import React, { FC } from "react";
import { ITemplateItem } from "dto/template.dto";
import GroupForm from "components/Question/QuestionTemplate/TemplateItemForm/GroupForm";

interface IProps {
  isSelected: boolean;
  stageWidth: number;
  stageHeight: number;
  templateItem: ITemplateItem;
  selectTemplateItem: (templateItem: ITemplateItem) => void;
}

const TemplateItemForm: FC<IProps> = (props) => {
  return (
    <GroupForm
      isSelected={props.isSelected}
      stageWidth={props.stageWidth}
      stageHeight={props.stageHeight}
      templateItem={props.templateItem}
      selectTemplateItem={props.selectTemplateItem}
    />
  );
};

export default TemplateItemForm;
