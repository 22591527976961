import React, { FC, useState } from "react";
import KonvaGroup from "components/Question/QuestionTemplate/KonvaGroups/KonvaGroup";
import GroupButtonRadio from "components/Question/QuestionTemplate/KonvaGroups/GroupButtonRadio";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
  answers: string[];
  answer_correct: string;
}

const GroupType13: FC<IProps> = (props) => {
  const [answerCurrent, setAnswer] = useState("");

  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
    >
      <KonvaGroup
        x={0}
        y={props.height / 2 - (props.answers?.length * 64 - 16) / 2}
        width={props.width}
        height={props.answers?.length * 64 - 16}
      >
        {props.answers?.map((answer, key) => (
          <GroupButtonRadio
            x={0}
            y={key * (48 + 16)}
            key={key}
            text={answer}
            width={props.width}
            height={48}
            isSelected={answerCurrent === answer}
            onClick={() => setAnswer(answer)}
          />
        ))}
      </KonvaGroup>
    </KonvaGroup>
  );
};

export default GroupType13;
