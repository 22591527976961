import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Grid, Card, Avatar, Typography, Badge } from "@material-ui/core";
import { State } from "store/types";
import { ITask } from "dto/user.dto";
import { ILaboratory } from "dto/laboratory.dto";
import * as utils from "utils/laboratory.utils";
import ProgressBar from "components/Common/ProgressBar";
import FormButtonIcon from "components/Form/FormButtonIcon";

const useStyles = makeStyles(() => ({
	root: { 
    textAlign: "center",
    marginLeft: 70,
    "& .MuiBadge-badge": { top: "50%", left: 0 },
  },
	avatar: { 
    width: 140,
    height: 140, 
    border: "3px solid #f0f0f0",
  },
	card: { 
    gap: 10,
    height: 100,
    display: "flex",
    padding: "0 40px 0 100px", 
    maxWidth: "100%",
    textAlign: "initial",
    boxShadow: "0px 4px 0px 0px #f0f0f0",
    alignItems: "center",
    borderRadius: 16, 
    backgroundColor: "white",
  },
  content: { width: 500, height: 50 },
  h5: { color: "#505050", fontWeight: 700 },
}));

interface IProps {
  tasks: ITask[];
  laboratory: ILaboratory;
  generateLaboratoryCertificate: () => void;
  toogleBackgroundVideoAutoplay?: () => void;
}

const LaboratoryInfo: FC<IProps> = (props) => {
  const classes = useStyles();
  const user = useSelector((state: State) => state.user.user);
  const max = !!props?.laboratory?.modules?.length ? props?.laboratory?.modules?.length : 1;
  const progress = utils.getLaboratoryProgress(props.laboratory, props.tasks).progress;
  const percentage = utils.getLaboratoryProgress(props.laboratory, props.tasks).percentage;

  return (
    <Grid className={classes.root} item>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        badgeContent={
          <Avatar 
            className={classes.avatar} 
            src={props?.laboratory?.imageUrl} 
            alt=""
          />
        }
      >
        <Card className={classes.card}>
          {!!props.laboratory?.background_video_url && (
            <FormButtonIcon 
              image="/img/home/volume.svg"
              onClick={props.toogleBackgroundVideoAutoplay}
              secondary
            />
          )}
          <Grid className={classes.content} container>
            <Grid item xs={12}>
              <Typography className={classes.h5} variant="h5" noWrap>
                {props.laboratory?.displayName ?? props.laboratory?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ProgressBar 
                max={max} 
                color={props.laboratory?.menu_color} 
                value={progress} 
              />
            </Grid>
          </Grid>
          {!!user?.nit && percentage >= 1 && (
            <FormButtonIcon 
              image="/img/icons/certificate.svg"
              default
              onClick={props.generateLaboratoryCertificate}
            />
          )}
        </Card>
      </Badge>
    </Grid>
  );
}

export default LaboratoryInfo;
