import React, { FC, useEffect, useMemo, useRef } from "react";
import "gifler";
import { Image } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemGif: FC<IProps> = (props) => {
  const imageRef: any = useRef(null);
  const canvas = useMemo(() => {
    const node = document.createElement("canvas");
    return node;
  }, []);

  useEffect(() => {
    let anim: any;
    // @ts-ignore
    window.gifler(props.templateItem?.image_url).get((a) => {
      anim = a;
      anim?.animateInCanvas(canvas);
      anim.onDrawFrame = (ctx: any, frame: any) => {
        ctx.drawImage(frame.buffer, frame.x, frame.y);
        imageRef?.current?.getLayer()?.draw();
      };
    });

    return () => anim?.stop();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.templateItem.image_url, canvas]);

  return (
    <Image
      image={canvas}
      ref={imageRef}
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      width={props.templateItem.width}
      height={props.templateItem.height}
      scaleX={props.templateItem?.scaleX}
      scaleY={props.templateItem?.scaleY}
    />
  );
};

export default TemplateItemGif;
