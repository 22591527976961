import React, { FC, useState } from "react";
import KonvaGroup from "components/Question/QuestionTemplate/KonvaGroups/KonvaGroup";
import GroupButtonRadio from "components/Question/QuestionTemplate/KonvaGroups/GroupButtonRadio";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
  answer: string;
  answer_a: string;
  answer_b: string;
  answer_c: string;
  answer_d: string;
  answer_correct: string;
}

const GroupType00: FC<IProps> = (props) => {
  const [answer, setAnswer] = useState("");

  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
    >
      <KonvaGroup
        x={0}
        y={props.height / 2 - 240 / 2}
        width={props.width}
        height={240}
      >
        <GroupButtonRadio
          x={0}
          y={0}
          text={props.answer_a}
          width={props.width}
          height={48}
          isSelected={answer === "A"}
          onClick={() => setAnswer("A")}
        />
        <GroupButtonRadio
          x={0}
          y={64}
          text={props.answer_b}
          width={props.width}
          height={48}
          isSelected={answer === "B"}
          onClick={() => setAnswer("B")}
        />
        <GroupButtonRadio
          x={0}
          y={128}
          text={props.answer_c}
          width={props.width}
          height={48}
          isSelected={answer === "C"}
          onClick={() => setAnswer("C")}
        />
        <GroupButtonRadio
          x={0}
          y={192}
          text={props.answer_d}
          width={props.width}
          height={48}
          isSelected={answer === "D"}
          onClick={() => setAnswer("D")}
        />
      </KonvaGroup>
    </KonvaGroup>
  );
};

export default GroupType00;
