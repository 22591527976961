import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ITemplate } from "dto/template.dto";
import KonvaStage from "components/Question/QuestionTemplate/KonvaStage";

const useStyles = makeStyles((theme) => ({
  type23: { 
    height: "100%", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    "&::-webkit-scrollbar": { width: 0, height: 0 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    '&::-webkit-scrollbar-thumb': { backgroundColor: "#c1c1c1", borderRadius: 4 },
  },
}));

interface IProps {
  templates: ITemplate[];
}

const Type23: FC<IProps> = (props) => {
  const classes = useStyles();
  const templates: ITemplate[] = !!props.templates?.length
    ? props.templates.map((template) => (template))
    : [];

  return (
    <Grid className={classes.type23} container>
      {templates?.map((template, key) => (
        <KonvaStage 
          key={key} 
          template={template} 
          templateItems={template?.template_items ?? []} 
        />
      ))}
    </Grid>
  )
}

export default Type23;
