import User from "services/user.services";
import { IUser } from "dto/user.dto";
import { IPermission } from "dto/permission.dto";

interface IFbAuth {
  uid: string;
  isEmpty: boolean;
  isLoaded: boolean;
  providerData: { uid: string; providerId: string; }[];
}

export const getUserByStorage = async (fbAuth: IFbAuth, uuiddb: string) => {
  const uid = fbAuth?.providerData[0]?.uid;
  const providerId = fbAuth?.providerData[0]?.providerId;
  if (!!uuiddb) {
    const user: IUser = await User.getUserByNit(uuiddb);
    if (
      !!user &&
      ((providerId === "password" && uid === user.email) ||
        (providerId === "phone" && uid === user.phone))
    ) {
      return user;
    } else return null;
  } else return null;
}
export const getUserByProvider = async (fbAuth: IFbAuth) => {
  const uid = fbAuth?.providerData[0]?.uid;
  const providerId = fbAuth?.providerData[0]?.providerId; 
  if (providerId === "password") {
    const db = await User.getUserByEmail(uid);
    const data: IUser = !!db ? Object.keys(db).map((key) => db[key])[0] : null;
    return !!data ? data : null;
  } else if (providerId === "phone") {
    const db = await User.getUserByPhone(uid);
    const data: IUser = !!db ? Object.keys(db).map((key) => db[key])[0] : null;
    return !!data ? data : null;
  } else return null;
}
export const getUsersAndUserdb = async (data: IUser[], fbAuth: IFbAuth) => {
  const uid = fbAuth?.providerData[0]?.uid;
  const providerId = fbAuth?.providerData[0]?.providerId;
  if (!!data?.length && providerId === "password") {
    const user = data.filter((u) => (u.email === uid))[0];
    return { user: !!user ? user : null, users: data };
  } else if (!!data?.length && providerId === "phone") {
    const user = data.filter((u) => (u.phone === uid))[0];
    return { user: !!user ? user : null, users: data };
  } else {
    const db = await User.get();
    const users = Object.keys(db).map((key: any) => data[key]);
    if (providerId === "password") {
      const user = users.filter((u) => (u.email === uid))[0];
      return { user: !!user ? user : null, users };
    } else if (providerId === "phone") {
      const user = users.filter((u) => (u.email === uid))[0];
      return { user: !!user ? user : null, users };
    } else return { user: null, users };
  }
}

export const getRolLabel = (rol: string) => {
  if (rol === "Administrador") return "Súper administrador"
  else if (rol === "admin") return "Administrador"
  else if (rol === "Docente") return "Creador de contenido"
  else return "Usuario"
}

export const getPermissionsNewToUser = (user: IUser, data: IPermission[]) => {
  const value = user?.permissions ?? [];
  const noRepeat = data?.filter((d) => {
    const aux = value?.filter((v) => (
      v.laboratoryName === d.laboratoryName &&
      v.moduleName === d.moduleName
    ));
    return !aux?.length;
  });
  const permissions = [...value, ...noRepeat]
  return permissions?.sort((a, b) => a?.laboratoryName?.localeCompare(b?.laboratoryName));
}
