import React, { FC, createRef, useEffect, useState } from "react";
import { ITaskRequest } from "dto/user.dto";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import { fileWithPathInit, questionInit } from "initials";
import * as utils from "utils";
import Question from "components/Question/Question";
import Notebook from "components/Notebook/Notebook";
import DialogWarning from "components/DialogCommon/DialogWarning";
import NotebookContent from "components/Notebook/NotebookContent";
import NotebookActions from "components/Notebook/NotebookActions";

interface IProps {
  path: string;
  zoom: boolean;
  questions: IQuestion[];
  buttonColor: string;
  headerColor: string;
  languageAudio: string;
  backgroundColor: string;
  progressBarColor: string;
  buttonNextColorText: string;
  zoomOnClick: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  updateTask: (task: ITaskRequest) => void;
  openUnitSteps: () => void;
  openDialogSuccess: () => void;
  updateQuestionIndex?: (index: number) => void;
}

const NotebookQuestion: FC<IProps> = (props) => {
  const audioRef = createRef<HTMLAudioElement>();
  const [files, setFiles] = useState(utils.getQuestionFiles(props.questions));
  const [answers, setAnswers] = useState(utils.getQuestionAnswers(props.questions));
  const [loading, setLoading] = useState(false);
  const [audioSrc, setAudioSrc] = useState("");
  const [question, setQuestion] = useState<IQuestion | undefined>(undefined);
  const [answerRating, setAnswerRating] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [pdfNumberPage, setPdfNumberPage] = useState(1);
  const [pdfNumberPages, setPdfNumberPages] = useState(0);
  const [questionPreview, setQuestionPreview] = useState<IQuestionPreview | null>(null);
  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const [buttonSoundImage, setButtonSoundImage] = useState("/img/sound.png");
  const questionNumber = questionIndex + 1;
  const questionsTotal = props.questions?.length ?? 0;
  const dialogWarningSubtitle = question?.type === 21 
    ? "Todavía te quedan páginas por leer en este documento. ¿Deseas continuar a la siguiente actividad?" 
    : "";
  const [taskImage, setTaskImage] = useState("");
  const [taskQuestions, setTaskQuestions] = useState<IQuestion[]>([]);
  const questionFile = files[questionIndex] ?? fileWithPathInit;
  const buttonNextDisabledType16 = question?.type === 16 && !!questionFile?.file?.size;
  const questionWithoutAnswer = !utils.getQuestionWithoutAnswer(question);
  const buttonNextDisabled = 
    !!loading ||
    !!timeoutDisabled ||
    (!!questionWithoutAnswer && !buttonNextDisabledType16 && !answers[questionIndex]?.answer);
  document.querySelector("#lab_question_audio")?.addEventListener(
    "ended",
    () => setButtonSoundImage("/img/sound.png"), 
    false,
  );

  useEffect(() => {
    setAnswers(utils.getQuestionAnswers(props.questions));
    setQuestionIndex(0);
    setAnswerRating(false);
    setViewFeedback(false);
    !!props.updateQuestionIndex && props.updateQuestionIndex(0);
    if (!!props.questions?.length) {
      getType22FullScreen(props.questions, 0, true);
      getQuestionPreview(props.questions[0]);
    }

    setTaskImage("");
    // setAnswersRate([]);
    setTaskQuestions([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questions]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const typeTimeout= question?.type === 22 || question?.type === 25;
    if (!!typeTimeout && !!question?.timeout) {
      setTimeoutDisabled(true);
      const timeout = question.timeout * 60 * 1000;
      timer = setTimeout(() => { setTimeoutDisabled(false); }, timeout);
    }
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const getQuestionPreview = async (value: IQuestion) => {
    setLoading(true);
    
    const preview = await utils.getQuestionPreview(value, props.path);
    setQuestion(value);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setQuestionPreview(preview);
    
    if (!!preview.audio_wa && props.languageAudio === "1") {
      setAudioSrc(preview.audio_wa);
    } else if (!!preview.audio && props.languageAudio !== "1") {
      setAudioSrc(preview.audio);
    } else setAudioSrc("");

    setLoading(false);
  }

  const openDialogWarning = () => setDialogWarning(true);
  const closeDialogWarning = () => setDialogWarning(false);
  const acceptDialogWarning = () => {
    nextQuestionSave();
    closeDialogWarning();
  }

  const prevQuestion = () => {
    audioPause();
    prevQuestionSave();
  }
  const nextQuestion = () => {
    audioPause();
    const typeWarning = question?.type === 22 || question?.type === 25;
    const withoutAnswers = !utils.getQuestionWithoutAnswerExact(question ?? questionInit);
    if (question?.type === 21 && pdfNumberPage < pdfNumberPages) {
      openDialogWarning();
    } else if (!!typeWarning && !!question?.modal_warning) {
      openDialogWarning();
    } else if (!viewFeedback && !!question?.feedback && !!withoutAnswers) {
      setViewFeedback(true);
    } else nextQuestionSave();
  }

  const prevQuestionPdf = () => {
    if (question?.type === 21 && pdfNumberPage > 1) {
      audioPause();
      setPdfNumberPage(pdfNumberPage - 1);
    }
  }
  const nextQuestionPdf = () => {
    if (question?.type === 21 && pdfNumberPage < pdfNumberPages) {
      audioPause();
      setPdfNumberPage(pdfNumberPage + 1);
    }
  }

  const prevQuestionSave = () => {
    // const answersRateAux = answersRate.filter((t, i) => (i < (questionIndex - 1)));
    const taskQuestionsAux = taskQuestions.filter((t, i) => (i < (questionIndex - 1)));
    // answersRate.pop();
    // setAnswersRate(answersRateAux);
    setTaskQuestions(taskQuestionsAux);
    
    setAnswerRating(false);
    setViewFeedback(false);
    if (questionIndex > 0) {
      getType22FullScreen(props.questions, questionIndex - 1, false);
      getQuestionPreview(props.questions[questionIndex - 1]);
      setQuestionIndex(questionIndex - 1);
      !!props.updateQuestionIndex && props.updateQuestionIndex(questionIndex - 1);
    } else props.openUnitSteps();
  }
  const nextQuestionSave = () => {
    const taskQuestionsAux = [
      ...taskQuestions, 
      utils.getTaskQuestion(
        answers[questionIndex].answer,
        question ?? questionInit,
        taskImage, 
        questionFile?.file,
      ),
    ];
    setTaskQuestions(taskQuestionsAux);
    
    setAnswerRating(false);
    setViewFeedback(false);

    if ((props.questions.length - 1) > questionIndex) {
      getType22FullScreen(props.questions, questionIndex + 1, true);
      getQuestionPreview(props.questions[questionIndex + 1]);
      setQuestionIndex(questionIndex + 1);
      !!props.updateQuestionIndex && props.updateQuestionIndex(questionIndex + 1);
    } else {
      const answersIsGradable = answers?.filter((answer) => (answer.is_gradable));
      if (!!answersIsGradable?.length) {
        const questionsIsGradable = utils.getQuestionsIsGradable(props.questions);
        const answersWithRate = answersIsGradable.map((answer) => (
          utils.getAnswerRating(answer.answer, answer.question)
        ));
        const answersCorrect = answersWithRate.filter((rate) => (!!rate));
        const rate = Math.round(answersCorrect?.length / questionsIsGradable?.length * 5);
        props.updateTask({ rate, is_gradable: true, questions: taskQuestionsAux });
      } else props.updateTask({ rate: 5, questions: taskQuestionsAux, is_gradable: false, });
      props.openDialogSuccess();
    }
    props.handleAnalytics("perform_laboratory_question");
  }

  const updateTaskImage = (image: string) => {
    setTaskImage(image);
    props.updateTask({ device_image: image, remote_image: image });
  }

  const selectFile = (file: File, path: string) => {
    const array: {file: File, path: string}[] = [];
    for (let i = 0; i < files.length; i++) {
      if (questionIndex !== i) array.push(files[i]);
      else array.push({ file, path});
    } 
    setFiles(array);
  }
  const selectAnswer = (value: string) => {
    const answersAux = answers.map((answer, i) => (
      questionIndex === i ? { ...answer, answer: value } : answer
    ));
    setAnswers(answersAux);
    setAnswerRating(utils.getAnswerRating(value, props.questions[questionIndex]));
  }

  const soundOnClick = () => {
    if (!!audioSrc && !!audioRef?.current?.paused) {
      setButtonSoundImage("/img/pause.png");
      audioRef?.current?.play();
      props.handleAnalytics("play_laboratory_question_audio");
    } else if (!!audioSrc && !audioRef?.current?.paused)  {
      setButtonSoundImage("/img/play.png");
      audioRef?.current?.pause();
    } else setButtonSoundImage("/img/sound.png");
  }
  const audioPause = () => {
    setButtonSoundImage("/img/sound.png");
    if (!!audioSrc) audioRef?.current?.pause();
  }

  const getType22FullScreen = (questions: IQuestion[], index: number, next: boolean) => {
    const question = questions[index];
    const questionNext = questions[index + 1];
    const questionPrev = questions[index - 1];
    
    if (question?.type === 22 && !!question?.full_screen && !props.zoom) {
      props.zoomOnClick();
    } else if (!!next && questionPrev?.type === 22 && !!props.zoom) {
      props.zoomOnClick();
    } else if (!next && questionNext?.type === 22 && !!props.zoom) {
      props.zoomOnClick();
    }
  }
  
  return (
    <Notebook
      zoom={props.zoom} 
      loading={loading} 
      backgroundColor={props.backgroundColor}
    >
      <NotebookContent zoom={props.zoom}>
        {!!question && !!questionPreview && (
          <Question
            book
            zoom={props.zoom}
            file={files[questionIndex]}
            answer={answers[questionIndex]?.answer}
            preview={questionPreview}
            question={question}
            taskImage={taskImage}
            headerColor={props.headerColor}
            answerRating={answerRating}
            viewFeedback={viewFeedback}
            pdfPageNumber={pdfNumberPage}
            languageAudio={props.languageAudio}
            questionNumber={questionNumber}
            questionsTotal={questionsTotal}
            progressBarColor={props.progressBarColor}
            selectFile={selectFile}
            selectAnswer={selectAnswer}
            updateTaskImage={updateTaskImage}
            setPdfNumberPages={setPdfNumberPages}
          />
        )}
      </NotebookContent>
      <NotebookActions
        zoom={props.zoom}
        pdfNumberPage={pdfNumberPage}
        pdfNumberPages={pdfNumberPages}
        buttonSoundImage={buttonSoundImage}
        buttonNextDisabled={buttonNextDisabled}
        buttonNextColorText={props.buttonNextColorText}
        buttonBackOnClick={prevQuestion}
        buttonNextOnClick={nextQuestion}
        buttonZoomOnClick={props.zoomOnClick}
        buttonSoundOnClick={!!audioSrc ? soundOnClick : undefined}
        buttonPdfPrevOnClick={prevQuestionPdf}
        buttonPdfNextOnClick={nextQuestionPdf}
      />
      <DialogWarning 
        open={dialogWarning}
        title="¿Continuar a la siguiente actividad?"
        subtitle={dialogWarningSubtitle}
        onClose={closeDialogWarning}
        onClickAccept={acceptDialogWarning}
      />

      <audio id="lab_question_audio" ref={audioRef} src={audioSrc}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </Notebook>
  )
}

export default NotebookQuestion;
