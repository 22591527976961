import Entity from "services/entity.services";

export const getEntityByNit = async (nit: string) => {
  try {
    const db = await Entity.get();
    const entity = db?.filter((c) => (c.nit === nit))[0];
    if (!!entity?.nit) return entity;
    else return undefined;
  } catch (error) {
    console.log("Error Utils Get Entity By Nit: ", error);
    return undefined;
  }
}
