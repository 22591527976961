import React, { FC } from "react";
import { ITemplateItem } from "dto/template.dto";
import GroupType from "components/Question/QuestionTemplate/TemplateItemForm/GroupType";
import KonvaGroup from "components/Question/QuestionTemplate/KonvaGroups/KonvaGroup";
import GroupFormPin from "./GroupFormPin";
import GroupInformation from "components/Question/QuestionTemplate/KonvaGroups/GroupInformation";

interface IProps {
  isSelected: boolean;
  stageWidth: number;
  stageHeight: number;
  templateItem: ITemplateItem;
  selectTemplateItem: (templateItem: ITemplateItem) => void;
}

const GroupForm: FC<IProps> = (props) => {
  const x = props.stageWidth / 2 - 800 / 2;
  const y = props.stageHeight / 2 - 450 / 2;

  const onSelect = () => props.selectTemplateItem(props.templateItem);

  return !!props.templateItem?.is_pin && !props.isSelected ? (
    <GroupFormPin
      x={props.templateItem?.x}
      y={props.templateItem?.y}
      onClick={onSelect}
    />
  ) : (
    <KonvaGroup
      x={!!props.templateItem?.is_pin ? x : props.templateItem?.x}
      y={!!props.templateItem?.is_pin ? y : props.templateItem?.y}
      id={props.templateItem?.id}
      fill="#ffffff"
      width={props.templateItem?.width}
      height={props.templateItem?.height}
      stroke="#f0f0f0"
      scaleX={!!props.templateItem?.is_pin ? 1 : props.templateItem?.scaleX}
      scaleY={!!props.templateItem?.is_pin ? 1 : props.templateItem?.scaleY}
      cornerRadius={20}
    >
      <GroupInformation
        x={0}
        y={0}
        width={props.templateItem?.width / 2}
        height={props.templateItem?.height}
        templateItem={props.templateItem}
      />
      <GroupType
        x={props.templateItem?.width / 2}
        y={0}
        type={props.templateItem?.question_type}
        width={props.templateItem?.width / 2}
        height={props.templateItem?.height}
        templateItem={props.templateItem}
      />
    </KonvaGroup>
  );
};

export default GroupForm;
