import React, { FC } from "react";
import { ITemplateItem } from "dto/template.dto";
import KonvaGroup from "components/Question/QuestionTemplate/KonvaGroups/KonvaGroup";
import GroupType00 from "./GroupType00";
import GroupType06 from "./GroupType06";
import GroupType13 from "./GroupType13";

interface IProps {
  x: number;
  y: number;
  type?: number;
  width: number;
  height: number;
  templateItem: ITemplateItem;
}

const GroupType: FC<IProps> = (props) => {
  const answers = props.templateItem?.question_answers ?? [];
  const padding = 16;
  const answerCorrect = props.templateItem?.question_answer_correct ?? "";

  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
    >
      {props.type === 0 ? (
        <GroupType00
          x={padding}
          y={padding}
          width={props.width - padding * 2}
          height={props.height - padding * 2}
          answer=""
          answer_a={props.templateItem?.question_answer_a ?? ""}
          answer_b={props.templateItem?.question_answer_b ?? ""}
          answer_c={props.templateItem?.question_answer_c ?? ""}
          answer_d={props.templateItem?.question_answer_d ?? ""}
          answer_correct={answerCorrect}
        />
      ) : props.type === 6 ? (
        <GroupType06
          x={padding}
          y={padding}
          width={props.width - padding * 2}
          height={props.height - padding * 2}
          answers={answers}
          answer_correct={answerCorrect}
        />
      ) : props.type === 13 ? (
        <GroupType13
          x={padding}
          y={padding}
          width={props.width - padding * 2}
          height={props.height - padding * 2}
          answer_correct={answerCorrect}
        />
      ) : (
        <></>
      )}
    </KonvaGroup>
  );
};

export default GroupType;
