import React, { FC, useState } from "react";
import { Stage, Layer } from "react-konva";
import { ITemplate, ITemplateItem } from "dto/template.dto";
import { templateItemInit } from "initials/template.init";
import KonvaRect from "./KonvaRect";
import TemplateItemGif from "./TemplateItemGif";
import TemplateItemText from "./TemplateItemText";
import TemplateItemImage from "./TemplateItemImage";
import TemplateItemForm from "./TemplateItemForm";

interface IProps {
  template: ITemplate;
  templateItems: ITemplateItem[];
}

const KonvaStage: FC<IProps> = (props) => {
  const [templateItemSelected, setTemplateItemSelected] = useState(templateItemInit);
  const stageWidth = 800 * 1.2;
  const stageHeight = 450 * 1.2;

  const deselectTemplateItem = () => setTemplateItemSelected(templateItemInit);

  return (
    <Stage
      ref={props.template?.ref}
      width={stageWidth}
      height={stageHeight}
    >
      <Layer>
        <KonvaRect fill={props.template?.fill} onClick={deselectTemplateItem} />
        {props.template?.template_items?.map((templateItem, key) =>
          templateItem?.type === "form/form" ? (
            <TemplateItemForm
              key={key}
              isSelected={templateItem?.id === templateItemSelected?.id}
              stageWidth={stageWidth}
              stageHeight={stageHeight}
              templateItem={templateItem}
              selectTemplateItem={setTemplateItemSelected}
            />
          ) : templateItem?.type === "text/text" ? (
            <TemplateItemText key={key} templateItem={templateItem} />
          ) : templateItem?.type === "image/gif" ? (
            <TemplateItemGif key={key} templateItem={templateItem} />
          ) : (
            <TemplateItemImage key={key} templateItem={templateItem} />
          )
        )}
        {/* {props.template?.template_items?.map((templateItem, key) => (
          templateItem?.type === "image/gif" ? (
            <KonvaGif key={key} templateItem={templateItem} />
          ) : templateItem?.type === "text/text" ? (
            <KonvaText key={key} templateItem={templateItem} />
          ) : (
            <KonvaImage key={key} templateItem={templateItem} />
          )
        ))} */}
      </Layer>
    </Stage>
  )
}

export default KonvaStage;
