import React, { FC, useEffect, useState } from "react";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import Type02 from "components/Question/QuestionTypes/Type02";
import Type05 from "components/Question/QuestionTypes/Type05";
import Type15 from "components/Question/QuestionTypes/Type15";
import Type18 from "components/Question/QuestionTypes/Type18";
import Type19 from "components/Question/QuestionTypes/Type19";
import Type20 from "components/Question/QuestionTypes/Type20";
import Type21 from "components/Question/QuestionTypes/Type21";
import Type22 from "components/Question/QuestionTypes/Type22";
import Type23 from "components/Question/QuestionTypes/Type23";
import Type25 from "components/Question/QuestionTypes/Type25";
import DialogPopUp from "components/Question/DialogPopUp";
import QuestionContent from "components/Question/QuestionContent";

interface IProps {
  file: { file: File, path: string }; 
  zoom?: boolean;
  book?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  taskImage?: string;
  headerColor: string;
  answerRating: boolean;
  viewFeedback: boolean;
  pdfPageNumber: number;
  languageAudio: string;
  questionNumber: number;
  questionsTotal: number;
  progressBarColor: string;
  selectFile: (file: File, path: string) => void;
  selectAnswer: (answer: string) => void;
  updateTaskImage: (image: string) => void;
  setPdfNumberPages: (page: number) => void;
}

const Question: FC<IProps> = (props) => {
  const [dialogPopUp, setDialogPopUp] = useState(false);

  useEffect(() => { 
    if (!!props.question?.pop_up) setDialogPopUp(true);
    else setDialogPopUp(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.question]);

  const closeDialogPopUp = () => setDialogPopUp(false);

  return (
    <>
      {props.question?.type === 2 ? (
        <Type02
          book={props.book}
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          languageAudio={props.languageAudio}
          questionNumber={props.questionNumber}
          questionsTotal={props.questionsTotal}
          progressBarColor={props.progressBarColor}
          selectAnswer={props.selectAnswer}
        />
      ) : props.question?.type === 5 ? (
        <Type05  
          book={props.book}         
          answer={props.answer}  
          preview={props.preview} 
          question={props.question}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          languageAudio={props.languageAudio}
          questionNumber={props.questionNumber}
          questionsTotal={props.questionsTotal}
          progressBarColor={props.progressBarColor}
          selectAnswer={props.selectAnswer}
        />
      ) : props.question?.type === 15 ? (
        <Type15 
          book={props.book} 
          preview={props.preview}
          question={props.question} 
          headerColor={props.headerColor} 
        />
      ) : props.question?.type === 18 ? (
        <Type18 
          url={props.preview.video ?? ""} 
          playing={props.question?.video_autoplay} 
        />
      ) : props.question?.type === 19 ? (
        <Type19 
          url={props.preview.video ?? ""} 
          playing={props.question?.video_autoplay}
        />
      ) : props.question?.type === 20 ? (
        <Type20 
          preview={props.preview}
          question={props.question} 
          headerColor={props.headerColor} 
        />
      ) : props.question?.type === 21 ? (
        <Type21 
          pdf={props.preview?.pdf}
          pdfWeb={props.preview?.pdf_web}
          pageNumber={props.pdfPageNumber} 
          setNumberPages={props.setPdfNumberPages}
        />
      ) : props.question?.type === 22 ? (
        <Type22 question={props.question?.question} />
      ) : props.question?.type === 23 ? (
        <Type23 templates={props.question?.templates ?? []} />
      ) : props.question?.type === 25 ? (
        <Type25 answer={props.answer} selectAnswer={props.selectAnswer} />
      ) : (
        <QuestionContent
          book={props.book}
          zoom={props.zoom}
          file={props.file}
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          taskImage={props.taskImage}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          languageAudio={props.languageAudio}
          questionNumber={props.questionNumber}
          questionsTotal={props.questionsTotal}
          progressBarColor={props.progressBarColor}
          selectFile={props.selectFile}
          selectAnswer={props.selectAnswer}
          updateTaskImage={props.updateTaskImage}
        /> 
      )}
      
      <DialogPopUp 
        open={dialogPopUp}
        properties={props.question?.pop_up_properties}
        propertiesPreview={props.preview?.pop_up_properties}
        onClose={closeDialogPopUp} 
      />
    </>
  )
}

export default Question;
