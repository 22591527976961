import React, { FC } from "react";
import { Rect } from "react-konva";

interface IProps {
  x: number;
  y: number;
  onClick?: () => void;
}

const FormRectPin: FC<IProps> = (props) => {
  return (
    <Rect
      x={props.x}
      y={props.y}
      fill="#d9d9d9"
      width={50}
      height={50}
      draggable
      cornerRadius={50}
      onTap={props.onClick}
      onClick={props.onClick}
    />
  );
};

export default FormRectPin;
