import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { IRouterParams } from "dto/router.dto";
import { State } from "store/types";
import * as utils from "utils";
import { history } from "configureStore";
import * as actions from "store/actions";
import { challengerInit, laboratoryInit, moduleInit } from "initials";
import Page404 from "components/Pages/Page404";
import Challenger from "components/Challenger/Challenger";
import PageLayout from "components/Pages/PageLayout";
import ModulesList from "components/Laboratory/ModulesList";
import LaboratoryButton from "components/Home/LaboratoryButton";
import { messageError } from "components/Common/Toast";

interface IProps extends RouteComponentProps<IRouterParams> {}

const LaboratoryPublicPage: FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const entity = useSelector((state: State) => state.entity.entity);
  const taskLoading = useSelector((state: State) => state.user.taskLoading);
  const anonymousUser = useSelector((state: State) => state.anonymousUser.anonymousUser);
  const dialogSuccess = useSelector((state: State) => state.home.modalSuccess);
  const [zoom, setZoom] = useState(false);
  const [laboratory, setLaboratory] = useState(laboratoryInit);
  const [tasksDraft, setTasksDraft] = useState<ITask[]>([]);
  const [moduleCurrent, setModuleCurrent] = useState(moduleInit);
  const [viewModuleList, setViewModuleList] = useState(false);
  const [challengerCurrent, setChallengerCurrent] = useState(challengerInit);
  const [laboratoryLoading, setLaboratoryLoading] = useState(false);
  const [laboratoryBackgroundVideoAutoplay, setLaboratoryBackgroundVideoAutoplay] = useState(false);
  const tasks = laboratory?.state === "draft" 
    ? tasksDraft
    : !!user?.tasks?.length
    ? user?.tasks
    : !!anonymousUser?.tasks?.length
    ? anonymousUser?.tasks 
    : [];
  const notebook = !!moduleCurrent?.name && !!challengerCurrent?.name;
  const backgroundImage = !!notebook && !!moduleCurrent?.background_image_url 
    ? moduleCurrent.background_image_url 
    : !!viewModuleList
    ? laboratory?.background_image_url 
    : !notebook && !viewModuleList && !!entity?.background_image_url 
    ? entity?.background_image_url 
    : "";
  const backgroundVideo = !!notebook && !!moduleCurrent?.background_video_url 
    ? moduleCurrent.background_video_url
    : !!viewModuleList && !!laboratory?.background_video_url 
    ? laboratory?.background_video_url 
    : "";
  const backgroundVideoAudio = !!notebook && !!moduleCurrent?.background_video_url 
    ? !!moduleCurrent?.background_video_audio
    : laboratory?.background_video_audio;
  const backgroundVideoAutoplay = !!notebook && !!moduleCurrent?.background_video_url 
    ? !!moduleCurrent?.background_video_url 
    : laboratoryBackgroundVideoAutoplay;

  useEffect(() => { 
    getLaboratory();
    
    return () => { 
      setLaboratory(laboratoryInit);
      setLaboratoryLoading(false);
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLaboratory = async () => {
    setLaboratoryLoading(true);
    const key = props.match?.params?.laboratory_key;
    const lab = await utils.getLaboratoryByKey(key);
    if (lab?.state === "private" && !!user?.nit) {
      const laboratoryPrivate = await utils.getLaboratoryPrivateWithFiles(lab, user);
      setLaboratory(laboratoryPrivate);
      dispatch(actions.analytics("access_laboratory_private", {}));
    } else if (lab?.state === "draft" && !!user?.nit) {
      const laboratoryDraft = await utils.getLaboratoryDraft(lab, user);
      setLaboratory(laboratoryDraft);
      dispatch(actions.analytics("access_laboratory_draft", {}));
    } else if (lab?.state === "public") {
      const laboratoryPublic = await utils.getLaboratoryPublicWithFiles(lab);
      setLaboratory(laboratoryPublic);
      dispatch(actions.analytics("access_laboratory_public", {}));
    }
    setLaboratoryLoading(false);
  }

  const selectLaboratory = () => {
    setViewModuleList(true);
    setLaboratoryBackgroundVideoAutoplay(laboratory?.background_video_autoplay);
  }
  const deselectLaboratory = () => {
    setViewModuleList(false);
    setLaboratoryBackgroundVideoAutoplay(false);
  }
  const selectModule = (value: IModule) => {
    if (value?.challenges?.length) setModuleCurrent(value);
    else messageError(t("El módulo no tiene unidades"));
  }
  const deselectModule = () => {
    deselectChallenger();
    setModuleCurrent(moduleInit);
    !!zoom && closeZoom();
  }
  const selectChallenger = (value: IChallenger) => {
    const aux = utils.getChallengerWithTasks(laboratory.name, moduleCurrent, tasks);
    if (value?.name === aux?.name && value?.questions?.length === aux?.questions?.length) {
      setChallengerCurrent(aux);
    } else setChallengerCurrent({ ...value, selected: true });
  }
  const deselectChallenger = () => setChallengerCurrent(challengerInit);

  const closeZoom = () => setZoom(false);
  const toogleZoom = () => setZoom((v) => !v);
  const openDialogSuccess = () => dispatch(actions.openModalSuccess());
  const closeDialogSuccess = () => dispatch(actions.closeModalSuccess());
  
  const createUserTask = (task: ITask) => {
    dispatch(actions.createUserTask({
      task, 
      tasks, 
      module: moduleCurrent, 
      challenger: challengerCurrent,
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const createUserTaskDraft = (task: ITask) => {
    const tasksNew = [...tasksDraft, task];
    const challengerBlocked = 
      !!laboratory?.challenges_blocked 
      && laboratory?.challenges_rate_min > task?.rate;

    setTasksDraft(tasksNew);
    const next = utils.getChallengerNextWithTasks(
      laboratory?.name,
      moduleCurrent, 
      challengerCurrent, 
      tasksNew,
      challengerBlocked,
    );
    createTaskCallback(next.challenger, next.type, task);
    dispatch(actions.analytics("perform_laboratory_challenger"));
    closeDialogSuccess();
  }
  const createUserAnonymousTask = (task: ITask) => {
    dispatch(actions.createUserAnonymousTask({
      task, 
      tasks, 
      module: moduleCurrent, 
      challenger: challengerCurrent, 
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const createUserAnonymousWithTasks = (task: ITask) => {
    dispatch(actions.createUserAnonymousWithTasks({
      task,
      tasks,
      module: moduleCurrent,
      challenger: challengerCurrent,
      laboratory: laboratory,
      createTaskCallback,
    }));
  }
  const createTaskCallback = (next?: IChallenger, type?: string, task?: ITask) => {
    if (!!next) {
      setChallengerCurrent(next);
    } else if (type === "finished") {
      deselectModule();
      dispatch(actions.analytics("perform_laboratory_module"));
    } else deselectChallenger();

    if (!!task) getActionLaboratoryCompleted(task);
  }
  const updateUserTaskDraft = (task: ITask) => {
    const tasksNew = utils.updateTask(tasks, task);
    setTasksDraft(tasksNew);
    deselectChallenger();
    dispatch(actions.analytics("perform_laboratory_challenger"));
    closeDialogSuccess();
  }
  const saveUserTask = (value: ITask) => {
    const task: ITask = {
      ...value,
      date: new Date().getTime().toString(),
      module: moduleCurrent?.name,
      laboratory: laboratory?.name,
      challenger: challengerCurrent?.name,
    }
    const state = laboratory?.state;
    if (state === "private" && !!user?.nit && !!challengerCurrent.selected) {
      dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else if (state === "private" && !!user?.nit) {
      createUserTask(task);
    } else if (state === "draft" && !!user?.nit && !!challengerCurrent.selected) {
      updateUserTaskDraft(task);
    } else if (state === "draft" && !!user?.nit) {
      createUserTaskDraft(task);
    } else if (state === "public" && !!user?.nit && !!challengerCurrent.selected) {
      dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else if (state === "public" && !!user?.nit) {
      createUserTask(task);
    } else if (state === "public" && !!anonymousUser?.uuid && !!challengerCurrent.selected) {
      dispatch(actions.updateUserAnonymousTask(task, tasks, deselectChallenger));
    } else if (state === "public" && !!anonymousUser?.uuid) {
      createUserAnonymousTask(task);
    } else if (state === "public" && challengerCurrent?.selected) {
    } else if (state === "public") {
      createUserAnonymousWithTasks(task);
    }
  }
  const getActionLaboratoryCompleted = (task: ITask) => {
    const completed = utils.getLaboratoryCompletedUsingProgress(
      laboratory, 
      moduleCurrent, 
      challengerCurrent, 
      [...tasks, task],
    );
    const moduleLast = laboratory?.modules[laboratory?.modules?.length - 1];
    const challengerLast = moduleLast?.challenges[moduleLast?.challenges?.length - 1];
    const condition = 
      completed &&
      moduleLast?.name === moduleCurrent?.name && 
      challengerLast?.name === challengerCurrent?.name;
    
    const params = new URLSearchParams(window.location.search);
    const back_url = params.get("back_url");
    const queryString = window.location.search.substring(1);
    const paramsArray = queryString.split("&");
    let queryStringNew = "";
    const paramsArrayNew = paramsArray?.filter((p) => (!p?.includes("back_url")));
    for (let i = 0; i < paramsArrayNew?.length; i++) {
      if (!!queryStringNew) {
        queryStringNew = `${queryStringNew}&${paramsArrayNew[i]}`;
      } else queryStringNew = `${queryStringNew}${paramsArrayNew[i]}`;
    }
    const laboratoryRate = utils.getLaboratoryRate(laboratory, [...tasks, task]);

    if (laboratory?.state === "public") {
      if (condition && !!back_url && !!queryStringNew) {
        window.open(`${back_url}?${queryStringNew}&rate=${laboratoryRate}`, "_self");
      } else if (condition && !!user?.nit) {
        history.push("/home"); 
      } else if (condition && !!anonymousUser?.uuid && !!anonymousUser?.userType) {
        history.push("/explore");
      } else if (condition && !!anonymousUser?.uuid) {
        history.push("/anonymous-user");
      } else if (condition) history.push("/anonymous-user");
    } else if (laboratory?.state === "private") {
      if (condition && !!user?.nit && !!laboratory?.sequential_mode) {
        history.push("/home");
      }
    }
  }

  const generateLaboratoryCertificate = (laboratory: ILaboratory) => {
    dispatch(actions.generateLaboratoryCertificate(laboratory));
  }
  
  const toogleLaboratoryBackgroundVideoAutoplay = () => {
    setLaboratoryBackgroundVideoAutoplay((v) => !v);
  }

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <PageLayout
      zoom={zoom} 
      loading={laboratoryLoading}
      backgroundGif
      backgroundImage={backgroundImage}
      backgroundVideo={backgroundVideo}
      backgroundVideoAudio={backgroundVideoAudio}
      backgroundVideoAutoplay={backgroundVideoAutoplay}
    >
      {!!notebook ? (
        <Challenger
          zoom={zoom} 
          tasks={tasks}
          module={moduleCurrent} 
          challenger={challengerCurrent}
          laboratory={laboratory}
          taskLoading={taskLoading}
          dialogSuccess={dialogSuccess}
          languageAudio={laboratory?.language ?? ""}
          toogleZoom={toogleZoom}
          saveUserTask={saveUserTask}
          handleAnalytics={handleAnalytics}
          openDialogSuccess={openDialogSuccess}
          deselectChallenger={deselectChallenger}
        />
      ) : !!viewModuleList ? (
        <ModulesList
          tasks={tasks}
          laboratory={laboratory}
          userCurrent={user}
          moduleCurrent={moduleCurrent}
          onClickBack={deselectLaboratory}
          selectModule={selectModule}
          deselectModule={deselectModule}
          selectChallenger={selectChallenger}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
          toogleBackgroundVideoAutoplay={toogleLaboratoryBackgroundVideoAutoplay}
        />
      ) : !!laboratory?.name ? (
        <LaboratoryButton
          user={user}
          tasks={tasks}
          laboratory={laboratory} 
          entityName={entity?.name}
          selectContent={selectLaboratory}
          generateLaboratoryCertificate={generateLaboratoryCertificate}
        />
      ) : (
        <Page404 />
      )}
    </PageLayout>
  )
}

export default LaboratoryPublicPage;
