import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Grid, Button, Typography } from "@material-ui/core";
import { soundClick } from "utils";

const useStyles = makeStyles(() => ({
  content: { width: "100%" },
  accept: { 
    backgroundColor: '#ff6315',
    color: '#ffffff',
    borderRadius: 10,
    '&:hover': { backgroundColor: '#ffffff', color: '#ff6315' },
    '& .MuiTypography-root': { 
      fontSize: '17px',
      fontWeight: 500,
      textTransform: 'initial', 
    },
  }
}));

interface IProps {
  taskImage?: string;
  updateTaskImage: (image: string) => void
}

const UploadEvidence: FC<IProps> = ({ taskImage, updateTaskImage }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  
  const fileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = !!e.target.files && e.target.files[0];
    if (!!file) updateTaskImage(file.name)
  };

  return (
    <Grid container>
      <Grid container justifyContent="center">
        <input 
          id="button-file" 
          accept="image/*" 
          type="file" 
          multiple 
          onChange={fileChange}
          style={{display: 'none'}} 
        />
        <label className={classes.content} htmlFor="button-file">
          <Button 
            className={classes.accept} 
            variant="contained" 
            component="span"
            onMouseDown={soundClick}
            fullWidth
          >
            {!taskImage ? (
              <Typography>{t("Subir evidencia")}</Typography>
            ) : (
              <Typography noWrap>{taskImage}</Typography>
            )}
          </Button>
        </label>
      </Grid>
    </Grid>
  )
}

export default UploadEvidence;
