import React, { ChangeEvent, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Grid, Badge, Avatar, IconButton, CircularProgress, Typography } from "@material-ui/core";
import { IUser } from "dto/user.dto";
import * as utils from "utils/user.utils";

const useStyles = makeStyles(() => ({
  root: {
    height: "fit-content",
    padding: "16px 14px",
    borderBottom: "2.6px solid #f0f0f0",
  },
  photo: { 
    width: 90, 
    position: "relative", 
    marginRight: 8, 
  },
  edit: {
    width: "Hug (28px)",
    padding: 0,
    "& img": { width: "100%" },
    "& input": { display: "none" }, 
  },
  avatar: { 
    width: 90,
    height: 90, 
    border: "2.6px solid #f0f0f0", 
    fontSize: 50, 
  },
  progress: { 
    top: 0, 
    width: 90,
    bottom: 0,
    zIndex: 1,
    position: "absolute", 
    backgroundColor: "rgba(255, 255, 255, 0.45)",
    "& .MuiCircularProgress-root": { color: "#ff6315", },
  },
  text: { width: "calc(100% - 100px)", alignItems: "center", },
  h6: { 
    color: "#5f5f5f", 
    fontWeight: 700, 
    lineHeight: 1.2, 
  },
  body1: { color: "#929292", fontWeight: 500, },
}));

interface IProps {
  user: IUser;
  loading: boolean;
  updatePhoto: (data: File) => void;
}

const MenuPhoto: FC<IProps> = ({ user, loading, updatePhoto }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const uploadImage = async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (files?.length) updatePhoto(files[0]);
  }

  return (
    <Grid className={classes.root} container>
      <Grid className={classes.photo} container>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <IconButton className={classes.edit} component="label" disabled={loading}>
              <img src="/img/profile/edit.png" alt="" />
              <input type="file" value="" accept="image/*" onChange={uploadImage} />
            </IconButton>
          }
        >
          <Avatar className={classes.avatar} src={user.photo_url ?? ""}  alt={user.name} />
        </Badge>
        {loading && (
          <Grid className={classes.progress} container>
            <CircularProgress size={90} />
          </Grid>
        )}
      </Grid>
      <Grid className={classes.text} container>
        <Grid item xs={12}>
          <Typography className={classes.h6} variant="h6">
            {user.name}
          </Typography>
          <Typography className={classes.body1}>
            {t(utils.getRolLabel(user.rol))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MenuPhoto;
