import React, { FC, ReactNode } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page404 from "components/Pages/Page404";
import Loading from "components/Common/Loading";
import Background from "components/Common/Background";
import AppBarLayout from "layouts/AppBarLayout";

const useStyles = makeStyles((theme) => ({
  root: { height: "100vh" },
  page: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
    backgroundColor: "white",
    "&.zoom": { height: "100%" },
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps {
  zoom?: boolean;
  loading?: boolean;
  children: ReactNode;
  notFoundPage?: boolean;
  backgroundGif?: boolean;
  backgroundImage?: string;
  backgroundVideo?: string;
  hideButtonLanguage?: boolean;
  backgroundVideoAudio?: boolean;
  backgroundVideoAutoplay?: boolean;
}

const PageLayout: FC<IProps> = (props) => {
  const classes = useStyles();
  const background = props.backgroundGif || props.backgroundImage || props.backgroundVideo;

  return (props.notFoundPage ? (
    <Page404 />
  ) : (
    <Grid className={classes.root} container>
      <AppBarLayout 
        zoom={props.zoom}
        hideButtonLanguage={props.hideButtonLanguage} 
      />

      <Grid className={clsx(classes.page, props.zoom && "zoom")} container>
        {background && (
          <Background
            backgroundGif={props.backgroundGif}
            backgroundImage={props.backgroundImage}
            backgroundVideo={props.backgroundVideo}
            backgroundVideoAudio={props.backgroundVideoAudio}
            backgroundVideoAutoplay={props.backgroundVideoAutoplay}
          />
        )}
        
        <Grid className={classes.content} container>
          {props.children}
        </Grid>
      </Grid>

      <Loading loading={props.loading} />
    </Grid>
  ));
};

export default PageLayout;
