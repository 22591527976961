import React, { FC, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import Type00 from "./QuestionTypes/Type00";
import Type03 from "./QuestionTypes/Type03";
import Type04 from "./QuestionTypes/Type04";
import Type07 from "./QuestionTypes/Type07";
import Type13 from "./QuestionTypes/Type13";
import Type14 from "./QuestionTypes/Type14";
import Type16 from "./QuestionTypes/Type16";
import Type17 from "./QuestionTypes/Type17";

const useStyles = makeStyles((theme) => ({
  types: {
    height: "100%",
    overflow: "auto",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    [theme.breakpoints.down("xs")]: { height: "fit-content", }, 
  },
}));

interface IProps {
  file: { file: File; path: string; };
  zoom?: boolean;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  taskImage?: string;
  answerRating: boolean;
  viewFeedback: boolean;
  selectFile: (file: File, path: string) => void;
  selectAnswer: (a: string) => void;
  updateTaskImage: (i: string) => void;
};

const QuestionTypes: FC<IProps> = (props) => {
  const classes = useStyles();
  const { type, answer_correct } = props.question;
  const [questionAnswers, setQuestionAnswers] = useState<string[]>([]);

  useEffect(() => {
    if (props.question?.type === 6) {
      setQuestionAnswers(props.question.answers ?? [])
    } else {
      setQuestionAnswers([
        props.question?.answer_a ?? "",
        props.question?.answer_b ?? "",
        props.question?.answer_c ?? "",
        props.question?.answer_d ?? "",
      ]);
    }

  }, [props.question]);

  const notFeedback = !answer_correct && 
    (!type || type === 0 || type === 1 || type === 3 || type === 6 || type === 7 || type === 13);

  return (
    <Grid className={classes.types} container>
      {(!type || type === 0 || type === 1 || type === 6) ? (
        <Type00 
          answer={props.answer}
          answers={questionAnswers}
          notFeedback={notFeedback}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          selectAnswer={props.selectAnswer}
        />
      ) : type === 3 ? (
        <Type03 
          answer={props.answer}
          answers={questionAnswers}
          question={props.question.question}
          notFeedback={notFeedback}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          selectAnswer={props.selectAnswer}
        />
      ) : (type === 4 || type === 8 || type === 9) ? (
        <Type04 
          answer={props.answer}
          question={props.question}
          taskImage={props.taskImage}
          selectAnswer={props.selectAnswer}
          updateTaskImage={props.updateTaskImage}
        />
      ) : type === 7 ? (
        <Type07 
          zoom={props.zoom}
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          notFeedback={notFeedback}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          selectAnswer={props.selectAnswer}
        />
      ) : type === 13 ? (
        <Type13 
          answer={props.answer}
          notFeedback={notFeedback}
          answerRating={props.answerRating}
          viewFeedback={props.viewFeedback}
          selectAnswer={props.selectAnswer} 
        />
      ) : type === 14 ? (
        <Type14
          answer={props.answer} 
          question={props.question}
          viewFeedback={props.viewFeedback}
          selectAnswer={props.selectAnswer}
        />
      ) : type === 16 ? (
        <Type16 
          file={props.file} 
          answer={props.answer} 
          selectFile={props.selectFile}
          selectAnswer={props.selectAnswer} 
        />
      ) : type === 17 ? (
        <Type17 
          fileUrl={props.preview?.file_download ?? ""}
          fileName={props.question?.file_download ?? ""}
        />
      ) : (
        <></>
      )}
    </Grid>
  )
}

export default QuestionTypes;
