import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { IQuestion, IQuestionPreview } from "dto/question.dto";
import Types from "components/Question/QuestionTypes";
import TypeDate from "./TypeDate";
import Information from "components/Question/QuestionInformation/Information";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  question: { 
    height: "100%", 
    flexWrap: "initial", 
    overflow: "auto",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
    [theme.breakpoints.down("xs")]: { flexWrap: "inherit" } 
  },
  half: {
    width: 2,
    height: "100%",
    borderLeft: "2px solid #00000021",
    [theme.breakpoints.down("xs")]: { display: "none" }, 
  },
  h6: { 
    color: "#5f5f5f", 
    fontWeight: 700,
    "& p": { fontFamily: "inherit !important" },
    "& span": { fontFamily: "inherit !important" },
  },
}));

interface IProps {
  total: number;
  answer: string;
  preview: IQuestionPreview;
  question: IQuestion;
  questionNumber: number;
  questionsTotal: number;
  prevQuestion: () => void;
  nextQuestion: () => void
  selectAnswer: (answer: string) => void;
}

const Question: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.question} container>
      <Information 
        preview={props.preview}
        question={props.question}
        answerRating={false}
        viewFeedback={false}
        languageAudio="0"
        questionNumber={props.questionNumber}
        questionsTotal={props.questionsTotal}
        progressBarColor="#e05700"
      >
        {props.question.type !== 3 && (
          <Typography className={classes.h6} variant="h6">
            {t(props.question.question)}
          </Typography>
        )}
      </Information>
      <Grid className={classes.half} container />
      {props.question?.type === -1 ? (
        <TypeDate 
          answer={props.answer}
          selectAnswer={props.selectAnswer}
        />
      ) : (
        <Types
          file={{ file: new File([], ""), path: "" }}
          zoom={false}
          answer={props.answer}
          preview={props.preview}
          question={props.question}
          answerRating={false}
          viewFeedback={false}
          selectFile={() => {}}
          selectAnswer={props.selectAnswer}
          updateTaskImage={() => {}}
        />
      )}
    </Grid>
  )
}

export default Question;
