import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { State } from "store/types";
import { IQuestion } from "dto/question.dto";
import { IRouterParams } from "dto/router.dto";
import * as actions from "store/actions";
import Survey from "components/Survey/Survey";
import Loading from "components/Common/Loading";
import Page404 from "components/Pages/Page404";
import Background from "components/Common/Background";
import AppBarLayout from "layouts/AppBarLayout";
import SurveyButton from "components/Home/SurveyButton";

const useStyles = makeStyles(() => ({
  root: { height: "100vh" },
  page: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
    "&.zoom": { height: "100%" },
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
    "&::-webkit-scrollbar": { width: 8 },
    "&::-webkit-scrollbar-track": { backgroundColor: "white" },
    "&::-webkit-scrollbar-thumb": { backgroundColor: "#c1c1c1", borderRadius: 8 },
  },
}));

interface IProps extends RouteComponentProps<IRouterParams> {}

const SurveyPublicPage: FC<IProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const survey = useSelector((state: State) => state.survey.surveyPublic);
  const loading = useSelector((state: State) => state.survey.surveyPublicLoading);
  const languageAudio = useSelector((s: State) => s.user.languageAudio);
  const saveAnswerLoading = useSelector((state: State) => state.survey.saveAnswerLoading);
  const dialogCongratulations = useSelector((s: State) => s.survey.dialogCongratulations);
  const [zoom, setZoom] = useState(false);
  const [surveyBook, setSurveyBook] = useState(false);
  const backgroundImage = !!survey.privacy
    ? "/img/background.svg"
    : !!survey.properties.wallpaper_url && !!surveyBook
    ? survey.properties.wallpaper_url
    : "/img/background/entorno_b1.png";
  const backgroundVideo = 
    !!survey.properties.background_video_url && !!surveyBook && !survey.privacy
    ? survey.properties.background_video_url 
    : "";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getSurveyPublic(); }, []);

  const getSurveyPublic = () => {
    const uuid = match.params.survey_uuid;
    dispatch(actions.getSurveyPublic(uuid));
  }

  const saveSurveyAnswers = (value: IQuestion[]) => {
    dispatch(actions.saveSurveyAnswers(survey.uuid, value));
  }

  const onClickZoom = () => setZoom(!zoom);
  const openSurveyBook = () => setSurveyBook(true);
  const closeSurveyBook = () => setSurveyBook(false);

  const closeCongratulations = () => dispatch(actions.closeCongratulations());

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <Grid className={classes.root} container>
      {!zoom && <AppBarLayout />}

      <Grid className={clsx(classes.page, zoom && "zoom")} container>
        <Background 
          backgroundImage={backgroundImage} 
          backgroundVideo={backgroundVideo} 
          backgroundVideoAudio
          backgroundVideoAutoplay
        >
        </Background>

        <Grid className={classes.content} container>
          {!survey?.privacy && !!survey?.questions?.length && !!surveyBook ? (
            <Survey 
              zoom={zoom} 
              survey={survey} 
              questions={survey.questions}
              languageAudio={String(languageAudio)}
              saveAnswerLoading={saveAnswerLoading}
              dialogCongratulations={dialogCongratulations}
              onClickZoom={onClickZoom}
              closeSurveyBook={closeSurveyBook}
              handleAnalytics={handleAnalytics}
              saveSurveyAnswers={saveSurveyAnswers}
              closeCongratulations={closeCongratulations}
            />
          ) : !survey?.privacy && !!survey?.questions?.length ? (
            <SurveyButton survey={survey} selectSurvey={openSurveyBook} />
          ) : (
            <Page404 />
          )}
        </Grid>
      </Grid>
      
      <Loading loading={loading} />
    </Grid>
  )
}

export default SurveyPublicPage;
