import React, { FC } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton } from "@material-ui/core";
import * as utils from "utils";
import BackSvg from "./BackSvg";
import FormButton from "components/Form/FormButton";
import FormIconButton from "components/Form/FormIconButton";
import NotebookButtonsPdf from "./NotebookButtonsPdf";

const useStyles = makeStyles((theme) => ({
  actions: { 
    height: 50, 
    alignItems: "flex-end",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: { 
      height: 60,
      padding: "0 8px", 
      alignItems: "center", 
    },
    "&.zoom": { height: 60, padding: "0 8px", alignItems: "center" },
  },
  space: { 
    gap: 8,
    width: 88,
    [theme.breakpoints.down("xs")]: { gap: 0, width: 40 }, 
  },
  icon: { 
    width: 40, 
    padding: 0, 
    "& .MuiSvgIcon-root": { color: "white", fontSize: 40 }, 
  },
  map: {[theme.breakpoints.down("xs")]: { display: "none" }},
}));

interface IProps {
  zoom?: boolean;
  pdfNumberPage?: number;
  pdfNumberPages?: number;
  buttonNextText?: string;
  buttonNextColor?: string;
  buttonSoundImage?: string;
  buttonNextDisabled?: boolean;
  buttonNextColorText?: string;
  buttonNextOnClick?: () => void;
  buttonBackOnClick?: () => void;
  buttonZoomOnClick?: () => void;
  buttonSoundOnClick?: () => void;
  buttonPdfPrevOnClick?: () => void;
  buttonPdfNextOnClick?: () => void;
}

const NotebookActions: FC<IProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonNextText = !!props.buttonNextText ? 
    props.buttonNextText: "Continuar";
  const buttonSoundImage = !!props.buttonSoundImage ? 
    props.buttonSoundImage : "/img/sound.png";
  const buttonNextColorText = !!props.buttonNextColorText ? 
    props.buttonNextColorText : "#ff6300";
  const rgba = utils.hexadecimalToRgba(buttonNextColorText);
  const lighter = utils.lighterColor(rgba, 0.4);

  return (
    <Grid className={clsx(classes.actions, props.zoom && "zoom")} container>
      <Grid className={classes.space} container>
        {props.buttonBackOnClick && (
          <FormIconButton
            boxShadow={`0px 2px 0px 0px ${lighter}`}
            borderColor={lighter}
            marginBottom={2}
            onClick={props.buttonBackOnClick}
          >
            <img src={BackSvg(lighter)} alt="" />
          </FormIconButton>
        )}
      </Grid>
      
      {!!props.pdfNumberPages && props.pdfNumberPages > 1 && (
        <NotebookButtonsPdf 
          numberPage={props.pdfNumberPage ?? 0}
          numberPages={props.pdfNumberPages}
          onClickPrev={props.buttonPdfPrevOnClick}
          onClickNext={props.buttonPdfNextOnClick}
        />
      )}
      
      {!!props.buttonNextOnClick && (
        <FormButton 
          color={buttonNextColorText}
          border={0}
          padding="8px 16px"
          minWidth={200}
          disabled={!!props.buttonNextDisabled}
          boxShadow={`0px 4px 0px 0px ${lighter}`}
          onClick={props.buttonNextOnClick}  
        >
          {t(buttonNextText)}
        </FormButton>
      )}
      
      <Grid className={classes.space} container justifyContent="flex-end">
        {!!props.buttonSoundOnClick && (
          <IconButton 
            className={classes.icon} 
            onClick={props.buttonSoundOnClick} 
            aria-label={t("Haga clic para reproducir el audio de la pregunta")}
          >
            <img src={buttonSoundImage} alt="" width="100%" />
          </IconButton>
        )}
        
        {!!props.buttonZoomOnClick && (
          <IconButton 
            className={clsx(classes.icon, classes.map)} 
            onClick={props.buttonZoomOnClick} 
            onMouseDown={utils.soundClick}
          >
            <img src="/img/book/zoom.svg" alt="" width="100%" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default NotebookActions;
