import React, { FC } from "react";
import { Rect } from "react-konva";

interface IProps {
  fill: string;
  onClick?: () => void;
}

const KonvaRect: FC<IProps> = (props) => {
  return (
    <Rect
      x={0}
      y={0}
      fill={props.fill}
      width={960} 
      height={540}
      cornerRadius={20}
      onTap={props.onClick}
      onClick={props.onClick}
    />
  );
};

export default KonvaRect;
