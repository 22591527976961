import React, { FC } from "react";
import { Text, Circle } from "react-konva";
import KonvaGroup from "./KonvaGroup";

interface IProps {
  x: number;
  y: number;
  text: string;
  width: number;
  height: number;
  isSelected?: boolean;
  onClick?: (() => void);
}

const GroupButtonRadio: FC<IProps> = (props) => {
  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      fill={props.isSelected ? "#37d31c" : "#ffffff"}
      width={props.width}
      height={props.height}
      stroke={props.isSelected ? "#2cc113" : "#f0f0f0"}
      strokeWidth={2}
      cornerRadius={16}
      onClick={props.onClick}
    >
      <Circle
        x={16}
        y={props.height / 2}
        fill={props.isSelected ? "#37d31c" : "#ffffff"}
        width={12}
        height={12}
        stroke={props.isSelected ? "#ffffff" : "#8d8d8d"}
      />
      {props.isSelected && (
        <Circle
          x={16}
          y={props.height / 2}
          fill="#ffffff"
          width={6}
          height={6}
        />
      )}
      <Text
        x={28}
        y={props.height / 2 - 8}
        fill={props.isSelected ? "#ffffff" : "#8d8d8d"}
        text={props.text}
        fontSize={18}
        fontFamily="Montserrat"
      />
    </KonvaGroup>
  );
};

export default GroupButtonRadio;
