import React, { FC } from "react";
import { Text } from "react-konva";
import { ITemplateItem } from "dto/template.dto";

interface IProps {
  templateItem: ITemplateItem;
}

const TemplateItemText: FC<IProps> = (props) => {
  return (
    <Text
      x={props.templateItem.x}
      y={props.templateItem.y}
      id={props.templateItem.id}
      fill={props.templateItem?.fill}
      text={props.templateItem.text}
      padding={4}
      fontSize={props.templateItem?.font_size}
      fontStyle={props.templateItem?.font_style}
      fontFamily={props.templateItem?.font_family}
      textDecoration={props.templateItem?.text_decoration}
    />
  );
};

export default TemplateItemText;
