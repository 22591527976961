import React, { FC, useRef } from "react";
import { Group, Rect } from "react-konva";

interface IProps {
  x: number;
  y: number;
  id?: string;
  fill?: string;
  width: number;
  height: number;
  stroke?: string;
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  isSelected?: boolean;
  strokeWidth?: number;
  cornerRadius?: number | number[];
  onClick?: () => void;
}

const KonvaGroup: FC<IProps> = (props) => {
  const refGroup = useRef<any>();

  return (
    <Group
      x={props.x}
      y={props.y}
      id={props.id}
      ref={refGroup}
      width={props.width}
      height={props.height}
      scaleX={props.scaleX}
      scaleY={props.scaleY}
      onTap={props.onClick}
      onClick={props.onClick}
    >
      <Rect
        x={0}
        y={0}
        fill={props.fill}
        width={props.width}
        height={props.height}
        stroke={props.stroke}
        opacity={props.opacity}
        strokeWidth={props.strokeWidth}
        cornerRadius={props.cornerRadius}
      />
      {props.children}
    </Group>
  );
};

export default KonvaGroup;
