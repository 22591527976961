import * as utils from "utils";
import { IQuestion, IQuestionAnswer, IQuestionPreview } from "dto/question.dto";
import { 
  questionSubtitles,
  questionPreviewInit,
  popUpPropertiesPreviewInit,
  feedbackPropertiesPreviewInit,
} from "initials/question.init";

const getAudioUrl = async (question: IQuestion, audio: string, path: string) => {
  const { type } = question;
  const notAudio = type !== 18 && type !== 19;

  if (!!notAudio && audio !== "audio_default.ogg") {
    const audioUrl = await utils.downloadFile(path, audio);
    return audioUrl;
  } else return "";
}
const getImageHeaderUrl = async (question: IQuestion, path: string) => {
  const { type, feedback, image_header, feedback_image } = question;
  const notType = type !== 18 && type !== 19 && type !== 21;

  if (!!notType && image_header !== "Logo.png" && image_header !== "ola_neutral.gif") {
    const url = await utils.downloadFile(path, question.image_header);
    if (!!url) return url;
    else return "/gif/default/question/image_header_default.gif"
  } else if (!!notType && !!feedback && !!feedback_image) {
    return "/gif/default/question/image_header_default.gif";
  } else return "/gif/default/question/image_header_default.gif";
}
const getQuestionVideoUrl = async (question: IQuestion, path: string) => {
  if (!!question?.video_url) {
    return question.video_url
  } else if (!!question?.video) {
    const videoUrl = await utils.downloadFile(path, question.video);
    return videoUrl ?? "";
  } else return "";
}
const getPopUpPropertiesPreview = async (question: IQuestion, path: string) => {
  try {
    if (!!question?.pop_up && !!question?.pop_up_properties) {
      const properties = {
        image: await utils.downloadFile(
          path, 
          question?.pop_up_properties?.image,
          popUpPropertiesPreviewInit.image
        ),
      }
      return properties;
    } else return popUpPropertiesPreviewInit;
  } catch (error) {
    return popUpPropertiesPreviewInit;
  }
}
const getFeedbackPropertiesPreview = async (question: IQuestion, path: string) => {
  try {
    if (
      !!question?.feedback && 
      !!question?.feedback_image && 
      !!question?.feedback_properties &&
      !getQuestionWithoutAnswerExact(question)
    ) {
      const properties = {
        complete_image: await utils.downloadFile(
          path, 
          question?.feedback_properties?.complete_image,
          feedbackPropertiesPreviewInit.complete_image
        ),
        complete_audio_fl: await utils.downloadFile(
          path, 
          question?.feedback_properties?.complete_audio_fl,
          feedbackPropertiesPreviewInit.complete_audio_fl,
        ),
        complete_audio_sl: await utils.downloadFile(
          path, 
          question?.feedback_properties?.complete_audio_sl,
          feedbackPropertiesPreviewInit.complete_audio_sl,
        ),
        incomplete_image: await utils.downloadFile(
          path, 
          question?.feedback_properties?.incomplete_image,
          feedbackPropertiesPreviewInit.incomplete_image,
        ),
        incomplete_audio_fl: await utils.downloadFile(
          path, 
          question?.feedback_properties?.incomplete_audio_fl,
          feedbackPropertiesPreviewInit.incomplete_audio_fl,
        ),
        incomplete_audio_sl: await utils.downloadFile(
          path, 
          question?.feedback_properties?.incomplete_audio_sl,
          feedbackPropertiesPreviewInit.incomplete_audio_sl,
        ),
      }
      return properties;
    } else return feedbackPropertiesPreviewInit;
  } catch (error) {
    return feedbackPropertiesPreviewInit;
  }
}

export const getAnswerRating = (answer: string, question?: IQuestion) => {
  switch (question?.type) {
    case 4: return !!answer;
    case 5: 
      const answers05 = answer.split(",");
      const alphabetAnswer = question?.alphabet_answer ?? [];
      return answers05.length === alphabetAnswer.length;
    case 8: return !!answer;
    case 9: return !!answer;
    case 14:
      let count = 0;
      const answers14 = answer.split(",");
      const answerCorrects = question?.answer_corrects ?? [];
      for (let i = 0; i < answers14.length; i++) {
        for (let j = 0; j < answerCorrects.length; j++) {
          if (answers14[i] === answerCorrects[j]) count = count + 1;
        }
      }
      return answerCorrects.length === answers14.length && answerCorrects.length === count;
    case 15: return true;
    case 16: return !!answer;
    case 17: return true;
    case 18: return true;
    case 19: return true;
    case 20: return true;
    case 21: return true;
    case 22: return true;
    case 23: return true;
    
    default: return !!answer && answer === question?.answer_correct;
  }
}

export const getTaskQuestion = (
  answer: string, 
  question: IQuestion, 
  taskImage?: string,
  file_name?: string | File ,
) => {
  const type = question.type ?? 0;
  const taskQuestion: IQuestion = {
    id: question?.id ?? "",
    type: type,
    audio: question.audio ?? "",
    title: question.title ?? "",
    answer: answer ?? "",
    audio_wa: question.audio_wa ?? "",
    question: question.question ?? "",
    subtitle: question.subtitle ?? "",
    description: question.description ?? "",
    header_color: question.header_color ?? "",
    image_header: question.image_header ?? "",
    image_optional: question.image_optional ?? "",
    background_color: question.background_color ?? "#ffffff",
  }

  if (type === 0 || type === 2 || type === 3) {
    return{
      ...taskQuestion, 
      answer_a: question.answer_a ?? "",
      answer_b: question.answer_b ?? "",
      answer_c: question.answer_c ?? "",
      answer_d: question.answer_d ?? "",
      answer_correct: question.answer_correct  ?? "",
    };
  } else if (type === 4 || type === 8) {
    return { ...taskQuestion };
  } else if (type === 5) {
    return{
      ...taskQuestion,
      alphabet_answer: question.alphabet_answer ?? [],
      alphabet_soup_resolve: question.alphabet_soup_resolve ?? [],
    };
  } else if (type === 6) {
    return({
      ...taskQuestion,
      answers: question.answers,
      answer_correct: question.answer_correct ?? "",
    });
  } else if (type === 7) {
    return{
      ...taskQuestion,
      image_a: question.image_a  ?? "",
      image_b: question.image_b  ?? "",
      image_c: question.image_c  ?? "",
      image_d: question.image_d  ?? "",
      answer_correct: question.answer_correct ?? "",
    };
  } else if (type === 9) {
    return({
      ...taskQuestion, 
      device_image: taskImage ?? "",
      remote_image: taskImage ?? "",
    });
  } else if (type === 13) {
    return{
      ...taskQuestion, 
      answer_correct: question.answer_correct  ?? "",
    };
  } else if (type === 14) {
    return({
      ...taskQuestion,
      answers: question.answers ?? [],
      answer_corrects: question.answer_corrects ?? [],
    });
  } else if (type === 16) {
    return({
      ...taskQuestion,
      file_name: file_name ?? "",
    });
  } else if (type === 17) {
    return({ 
      ...taskQuestion,
      file_download: question.file_download ?? "",
    });
  } else if (type === 18) {
    return({ 
      ...taskQuestion,
      video_url: question.video_url ?? "",
    });
  } else return taskQuestion;
}

export const getQuestionFiles = (data: IQuestion[]) => {
  const file = { file: new File([], ""), path: "" };
  const files: { file: File, path: string }[] = [];
  for (let i = 0; i < data?.length; i++) files.push(file);
  return files;
}
export const getQuestionAnswers = (questions: IQuestion[]) => {
  const answers = questions.map((question) => ({ 
    answer: "",
    question,
    is_gradable: getQuestionIsGradable(question),
  }));
  return answers;
}
export const getQuestionPreview = async (question: IQuestion, path: string) => {
  try {
    const data: IQuestionPreview = {
      type: question?.type,
      audio: await getAudioUrl(question, question?.audio, path),
      audio_wa: await getAudioUrl(question, question?.audio_wa, path),
      image_header: await getImageHeaderUrl(question, path),
      image_optional: await utils.downloadFile(path, question?.image_optional),
      pop_up_properties: await getPopUpPropertiesPreview(question, path),
      feedback_properties: await getFeedbackPropertiesPreview(question, path),
    }

    if (question?.type === 7) {
      return {
        ...data,
        image_a: await utils.downloadFile(path, question?.image_a ?? ""),
        image_b: await utils.downloadFile(path, question?.image_b ?? ""),
        image_c: await utils.downloadFile(path, question?.image_c ?? ""),
        image_d: await utils.downloadFile(path, question?.image_d ?? ""),
      }
    } else if (question?.type === 17) {
      return {
        ...data,
        file_download: await utils.downloadFile(path, question?.file_download ?? ""),
      }
    } else if (question?.type === 18 || question?.type === 19) {
      return {
        ...data,
        video: await getQuestionVideoUrl(question, path),
      }
    } else if (question?.type === 21) {
      return {
        ...data,
        pdf: await utils.downloadFile(path, question?.pdf ?? ""),
        pdf_web: await utils.downloadFile(path, question?.pdf_web ?? ""),
      }
    } else return data;
  } catch (error) {
    return questionPreviewInit;
  }
}
export const getQuestionSubtite = (type: number) => {
  return questionSubtitles.filter((q) => (q.type === type))[0];
}
export const getQuestionWithoutAnswer = (question?: IQuestion) => (
  question?.type === 15 || 
  question?.type === 17 ||
  question?.type === 18 ||
  question?.type === 19 ||
  question?.type === 20 ||
  question?.type === 21 ||
  question?.type === 22 ||
  question?.type === 23 ||
  question?.type === 24 ||
  question?.type === 25
);
export const getQuestionWithoutAnswerExact = (question?: IQuestion) => (
  question?.type === 4 || 
  question?.type === 8 || 
  question?.type === 9 || 
  question?.type === 15 || 
  question?.type === 16 || 
  question?.type === 17 || 
  question?.type === 18 || 
  question?.type === 19 || 
  question?.type === 20 || 
  question?.type === 21 ||
  question?.type === 22 ||
  question?.type === 23
);
export const getQuestionButtonNextDisabled = (
  question?: IQuestion, 
  answer?: IQuestionAnswer,
) => (
  (!utils.getQuestionWithoutAnswer(question) && !answer?.answer?.length) ||
  (question?.type === 8 && !!answer?.answer?.length && answer?.answer?.length > 50)
);

export const getQuestionType16FileName = async (
  path: string, 
  file: File | string,
  fileOld?: File |string,
) => {
  try {
    if (typeof file === "object" && file?.name) {
      const uuid = new Date().getTime().toString();
      const fileName = `${uuid}_${file?.name?.replace(/\s+/g, "")?.toLowerCase()}`;
      await utils.uploadFile(path, file, fileName);
      typeof fileOld === "string" && await utils.deleteFile(path, fileOld);
      return fileName;
    } else if (typeof file === "string" && !!file) {
      typeof fileOld === "string" && await utils.deleteFile(path, fileOld);
      return file;
    } else {
      typeof fileOld === "string" && await utils.deleteFile(path, fileOld);
      return "";
    }
  } catch (error) {
    return "";
  }
}

export const getQuestionRate = (question: IQuestion) => {

}

export const getQuestionsIsGradable = (questions: IQuestion[]) => {
  const questionsIsGradable = questions?.filter((question) => (
    getQuestionIsGradable(question)
  ));
  return questionsIsGradable;
}

export const getQuestionSkipLogic = (
  questions: IQuestion[], 
  question?: IQuestion,
  questionAnswer?: IQuestionAnswer,
) => {
  const skipLogic = getSkipLogic(question, questionAnswer?.answer);
  for (let i = 0; i < questions?.length; i++) {
    if (!!questions[i]?.id && questions[i].id === skipLogic) return i;
  }
  return undefined;
}

const getQuestionIsGradable = (question: IQuestion) => (
  question?.type !== 4 &&
  question?.type !== 8 &&
  question?.type !== 9 &&
  question?.type !== 15 &&
  question?.type !== 16 &&
  question?.type !== 17 &&
  question?.type !== 18 &&
  question?.type !== 19 &&
  question?.type !== 20 &&
  question?.type !== 21 &&
  question?.type !== 22 &&
  question?.type !== 23
);
const getSkipLogic = (question?: IQuestion, answer?: string) => {
  const type = question?.type;
  if (!!answer && (type === 0 || type === 2 || type === 3)) {
    if (answer === question?.answer_a) return question?.skip_logic_a;
    else if (answer === question?.answer_b) return question?.skip_logic_b;
    else if (answer === question?.answer_c) return question?.skip_logic_c;
    else if (answer === question?.answer_d) return question?.skip_logic_d;
  } else if (type === 6 && !!answer) {
    const answers = question?.answers ?? [];
    const skipLogicList = question?.skip_logic_list ?? [];
    for (let i = 0; i < answers?.length; i++) {
      if (answers[i] === answer) return skipLogicList[i]?.skip_logic;
    }
  } else if (type === 7 && !!answer) {
    if (answer === question?.image_a) return question?.skip_logic_a;
    else if (answer === question?.image_b) return question?.skip_logic_b;
    else if (answer === question?.image_c) return question?.skip_logic_c;
    else if (answer === question?.image_d) return question?.skip_logic_d;
  } else if (type === 13 && !!answer) { 
    if (answer === "true") return question?.skip_logic_a;
    else if (answer === "false") return question?.skip_logic_b;
  } else if (type === 14 && !!answer) {
    const answers = question?.answers ?? [];
    if (answers?.length <= 4) {
      let id = "";
      const skipLogicList = question?.skip_logic_list ?? [];
      const answersSelected = answer.split(",");
      for (let i = 0; i < answers?.length; i++) {
        for (let j = 0; j < answersSelected?.length; j++) {
          if (answers[i] === answersSelected[j]) {
            id = id + String(i)
          }
        }
      }
      const skipLogic = skipLogicList?.filter((s) => (String(s?.id) === String(id)))[0];
      return skipLogic?.skip_logic
    } else return question?.skip_logic;
  } else return question?.skip_logic;
}
