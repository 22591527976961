import React, { FC } from "react";
import { Rect } from "react-konva";
import KonvaGroup from "./KonvaGroup";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

const GroupProgressBar: FC<IProps> = (props) => {
  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      fill="#ff6300"
      width={props.width}
      height={props.height}
      opacity={0.3}
      cornerRadius={25}
    >
      <Rect
        x={0}
        y={0}
        width={props.width / 2}
        height={props.height}
        fill="#ff6300"
        cornerRadius={25}
      />
    </KonvaGroup>
  );
};

export default GroupProgressBar;
