import React, { FC, createRef, useEffect, useState } from "react";
import { IQuestion, IQuestionAnswer, IQuestionFile, IQuestionPreview } from "dto/question.dto";
import { questionAnswersInit, questionFilesInit } from "initials";
import * as utils from "utils/question.utils";
import Notebook from "components/Notebook/Notebook";
import Question from "components/Question/Question";
import DialogWarning from "components/DialogCommon/DialogWarning";
import NotebookActions from "components/Notebook/NotebookActions";
import NotebookContent from "components/Notebook/NotebookContent";

interface IProps {
  path: string;
  zoom?: boolean;
  loading?: boolean;
  questions: IQuestion[];
  headerColor: string;
  languageAudio: string;
  backgroundColor?: string;
  progressBarColor: string
  buttonNextColorText?: string;
  onClickZoom?: () => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  updateQuestionIndex?: (index: number) => void;
  saveQuestionsAnswers: (q: IQuestion[], a: IQuestionAnswer[], f: IQuestionFile[]) => void;
  closeNotebookQuestion: () => void;
}

const NotebookQuestion: FC<IProps> = (props) => {
  const audioRef = createRef<HTMLAudioElement>();
  const [question, setQuestion] = useState<IQuestion | undefined>(undefined);
  const [answerRating, setAnswerRating] = useState(false);
  const [viewFeedback, setViewFeedback] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);
  const [questionAudio, setQuestionAudio] = useState("");
  const [questionFiles, setQuestionFiles] = useState(questionFilesInit);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [pdfNumberPage, setPdfNumberPage] = useState(1);
  const [pdfNumberPages, setPdfNumberPages] = useState(0);
  const [questionIndexs, setQuestionIndexs] = useState<number[]>([]);
  const [questionAnswers, setQuestionAnswers] = useState(questionAnswersInit);
  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const [questionPreview, setQuestionPreview] = useState<IQuestionPreview | undefined>(undefined);
  const [buttonSoundImage, setButtonSoundImage] = useState("/img/sound.png");
  const [questionPreviewLoading, setQuestionPreviewLoading] = useState(false);
  const loading = !!props.loading || !!questionPreviewLoading;
  const buttonNextDisabled = 
    !!loading || 
    !!timeoutDisabled ||
    !!utils.getQuestionButtonNextDisabled(question, questionAnswers[questionIndex]);
  const dialogWarningSubtitle = question?.type === 21 
    ? "Todavía te quedan páginas por leer en este documento. ¿Deseas continuar a la siguiente actividad?" 
    : "";
  document.querySelector("#question_audio")?.addEventListener(
    "ended",
    () => setButtonSoundImage("/img/sound.png"), 
    false,
  );

  useEffect(() => {
    setAnswerRating(false);
    setViewFeedback(false);
    setQuestionIndex(0);
    setQuestionFiles(utils.getQuestionFiles(props.questions));
    setQuestionIndexs([]);
    setQuestionAnswers(utils.getQuestionAnswers(props.questions));
    !!props.updateQuestionIndex && props.updateQuestionIndex(0);
    if (!!props.questions?.length) {
      getQuestionPreview(props.questions[0]);
      getType22FullScreen(props.questions[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questions]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const typeTimeout= question?.type === 22 || question?.type === 25;
    if (!!typeTimeout && !!question?.timeout) {
      setTimeoutDisabled(true);
      const timeout = question.timeout * 60 * 1000;
      timer = setTimeout(() => { setTimeoutDisabled(false); }, timeout);
    } else setTimeoutDisabled(false);
    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const getQuestionPreview = async (value: IQuestion) => {
    setQuestionPreviewLoading(true);
    const preview = await utils.getQuestionPreview(value, props.path);
    const audioNew = !props.languageAudio && !!preview.audio
      ? preview.audio
      : !!props.languageAudio && !!preview.audio_wa
      ? preview.audio_wa
      : "";
    setQuestion(value);
    setQuestionAudio(audioNew);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setQuestionPreview(preview);
    setQuestionPreviewLoading(false);
  }

  const audioPause = () => {
    setButtonSoundImage("/img/sound.png");
    if (!!questionAudio) audioRef?.current?.pause();
  }
  const onClickSound = () => {
    if (!!questionAudio && !!audioRef?.current?.paused) {
      setButtonSoundImage("/img/pause.png");
      audioRef?.current?.play();
      props.handleAnalytics("play_laboratory_question_audio");
    } else if (!!questionAudio && !audioRef?.current?.paused)  {
      setButtonSoundImage("/img/play.png");
      audioRef?.current?.pause();
    } else setButtonSoundImage("/img/sound.png");
  }

  const prevQuestion = () => {
    audioPause();
    prevQuestionSave();
  }
  const nextQuestion = () => {
    audioPause();
    const typeWarning = question?.type === 22 || question?.type === 25;
    const withoutAnswers = !utils.getQuestionWithoutAnswerExact(question);
    if (question?.type === 21 && pdfNumberPage < pdfNumberPages) {
      openDialogWarning();
    } else if (!!typeWarning && !!question?.modal_warning) {
      openDialogWarning();
    } else if (!viewFeedback && !!question?.feedback && !!withoutAnswers) {
      setViewFeedback(true);
    } else nextQuestionSave();
  }
  const prevQuestionSave = () => {
    const indexLast = questionIndexs?.length - 1;
    const indexNew = questionIndexs[indexLast];
    const questionIndexsNew = questionIndexs?.filter((q, i) => (i !== indexLast));
    if (!!indexNew || indexNew === 0) {
      setQuestionIndex(indexNew);
      getQuestionPreview(props.questions[indexNew]);
      getType22FullScreen(props.questions[indexNew], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(indexNew);
    } else props.closeNotebookQuestion();

    setAnswerRating(false);
    setViewFeedback(false);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setQuestionIndexs(questionIndexsNew);
  }
  const nextQuestionSave = () => {
    const answer = questionAnswers[questionIndex];
    const skipIndex = utils.getQuestionSkipLogic(props.questions, question, answer);
    const questionIndexsNew = questionIndexs;
    questionIndexsNew.push(questionIndex);

    if (skipIndex === questionIndex) {
      props.saveQuestionsAnswers(props.questions, questionAnswers, questionFiles);
    } else if (!!skipIndex || skipIndex === 0) {
      setQuestionIndex(skipIndex);
      getQuestionPreview(props.questions[skipIndex]);
      getType22FullScreen(props.questions[skipIndex], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(skipIndex);
    } else if ((props.questions.length - 1) > questionIndex) {
      const indexNew = questionIndex + 1;
      setQuestionIndex(indexNew);
      getQuestionPreview(props.questions[indexNew]);
      getType22FullScreen(props.questions[indexNew], props.questions[questionIndex]);
      !!props.updateQuestionIndex && props.updateQuestionIndex(indexNew);
    } else props.saveQuestionsAnswers(props.questions, questionAnswers, questionFiles);
    
    setAnswerRating(false);
    setViewFeedback(false);
    setPdfNumberPage(1);
    setPdfNumberPages(0);
    setQuestionIndexs(questionIndexsNew);
    props.handleAnalytics("perform_survey_question");
  }

  const onClickPrevQuestionPdf = () => {
    if (question?.type === 21 && pdfNumberPage > 1) {
      audioPause();
      setPdfNumberPage(pdfNumberPage - 1);
    }
  }
  const onClickNextQuestionPdf = () => {
    if (question?.type === 21 && pdfNumberPage < pdfNumberPages) {
      audioPause();
      setPdfNumberPage(pdfNumberPage + 1);
    }
  }

  const selectQuestionFile = (value: File, path: string) => {
    const filesNew = questionFiles?.map((item, i) => (
      questionIndex === i ? { file: value, path } : item
    ));
    setQuestionFiles(filesNew);
  }
  const selectQuestionAnswer = (value: string) => {
    const answersNew = questionAnswers.map((answer, i) => (
      questionIndex === i ? { ...answer, answer: value } : answer
    ));
    setAnswerRating(utils.getAnswerRating(value, question));
    setQuestionAnswers(answersNew);
  }

  const openDialogWarning = () => setDialogWarning(true);
  const closeDialogWarning = () => setDialogWarning(false);
  const acceptDialogWarning = () => { nextQuestionSave(); closeDialogWarning(); }

  const getType22FullScreen = (questionNew: IQuestion, questionOld?: IQuestion) => {
    if (questionNew?.type === 22 && !!questionNew?.full_screen && !props.zoom) {
      !!props.onClickZoom && props.onClickZoom();
    } else if (questionOld?.type === 22 && !!questionOld?.full_screen && !!props.zoom) {
      !!props.onClickZoom && props.onClickZoom();
    }
  }

  return (
    <Notebook
      zoom={props.zoom}
      loading={loading}
      backgroundColor={props.backgroundColor}
    >
      <NotebookContent zoom={props.zoom}>
        {!!question && !!questionPreview && (
          <Question
            book
            zoom={props.zoom}
            file={questionFiles[questionIndex]}
            answer={questionAnswers[questionIndex]?.answer}
            preview={questionPreview}
            question={question}
            headerColor={props.headerColor}
            answerRating={answerRating}
            viewFeedback={viewFeedback}
            pdfPageNumber={pdfNumberPage}
            languageAudio={props.languageAudio}
            questionNumber={questionIndex + 1}
            questionsTotal={props.questions?.length ?? 0}
            progressBarColor={props.progressBarColor}
            selectFile={selectQuestionFile}
            selectAnswer={selectQuestionAnswer}
            updateTaskImage={() => {}}
            setPdfNumberPages={setPdfNumberPages}
          />
        )}
      </NotebookContent>
      <NotebookActions
        zoom={props.zoom}
        pdfNumberPage={pdfNumberPage}
        pdfNumberPages={pdfNumberPages}
        buttonSoundImage={buttonSoundImage}
        buttonNextDisabled={buttonNextDisabled}
        buttonNextColorText={props.buttonNextColorText}
        buttonBackOnClick={prevQuestion}
        buttonNextOnClick={nextQuestion}
        buttonZoomOnClick={props.onClickZoom}
        buttonSoundOnClick={!!questionAudio ? onClickSound : undefined}
        buttonPdfPrevOnClick={onClickPrevQuestionPdf}
        buttonPdfNextOnClick={onClickNextQuestionPdf}
      >
      </NotebookActions>
      <DialogWarning
        open={dialogWarning}
        title="¿Continuar a la siguiente actividad?"
        subtitle={dialogWarningSubtitle}
        onClose={closeDialogWarning}
        onClickAccept={acceptDialogWarning}
      />
      <audio id="question_audio" ref={audioRef} src={questionAudio}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </Notebook>
  )
}

export default NotebookQuestion;
