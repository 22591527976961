import React, { FC } from "react";
import { Text } from "react-konva";
import { ITemplateItem } from "dto/template.dto";
import KonvaGroup from "./KonvaGroup";
import GroupBubble from "./GroupBubble";
import GroupProgressBar from "./GroupProgressBar";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
  templateItem: ITemplateItem;
}

const GroupInformation: FC<IProps> = (props) => {
  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      cornerRadius={[32, 0, 0, 32]}
    >
      <KonvaGroup
        x={16}
        y={16}
        width={props.width - 32}
        height={props.height - 32}
      >
        <GroupProgressBar x={0} y={0} width={props.width - 32} height={12} />
        <KonvaGroup
          x={0}
          y={12 + 16}
          width={props.width - 32}
          height={props.height - 32 - 12 - 16}
        >
          <GroupBubble
            x={0}
            y={0}
            width={props.width - 32}
            height={100}
            subtitle={props.templateItem?.question_subtitle}
            description={props.templateItem?.question_description}
            header_color={props.templateItem?.question_header_color}
            image_header={props.templateItem?.question_image_header}
          />
          <Text
            x={0}
            y={100 + 16}
            fill="#5f5f5f"
            text={props.templateItem?.question_question}
            width={props.width - 32}
            fontSize={16}
            fontStyle="bold"
            fontFamily="Montserrat"
          />
        </KonvaGroup>
      </KonvaGroup>
    </KonvaGroup>
  );
};

export default GroupInformation;
