import React, { FC, useEffect, useState } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles, Grid } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ITask } from "dto/user.dto";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { IRouterParams } from "dto/router.dto";
import { State } from "store/types";
import * as utils from "utils";
import { history } from "configureStore";
import * as actions from "store/actions";
import Page404 from "components/Pages/Page404";
import Loading from "components/Common/Loading";
import Background from "components/Common/Background";
import Challenger from "components/Challenger/Challenger";
import ModulesList from "components/Laboratory/ModulesList";
import AppBarLayout from "layouts/AppBarLayout";
import { messageError } from "components/Common/Toast";

const useStyles = makeStyles(() => ({
  root: { height: "100vh" },
  page: { 
    height: "calc(100% - 70px)", 
    position: "relative", 
    "&.zoom": { height: "100%" },
  },
  content: {
    height: "100%",
    position: "relative", 
    overflow: "auto",
    alignItems: "center",
    justifyContent: "center",
  },
}));

interface IProps extends RouteComponentProps<IRouterParams> {}

const LaboratoryPage: FC<IProps> = ({ match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector((state: State) => state.user.user);
  const zoom = useSelector((state: State) => state.home.zoom);
  const module = useSelector((state: State) => state.module.module);
  const laboratory = useSelector((state: State) => state.laboratory.laboratory);
  const challenger = useSelector((state: State) => state.challenger.challenger);
  const taskLoading = useSelector((state: State) => state.user.taskLoading);
  const dialogSuccess = useSelector((state: State) => state.home.modalSuccess);
  const laboratoryLoading = useSelector((s: State) => s.laboratory.laboratoryLoading);
  const { isEmpty, isLoaded } = useSelector((s: State) => s.firebase.auth);
  const [laboratoryBackgroundVideoAutoplay, setLaboratoryBackgroundVideoAutoplay] = useState(false);
  const tasks = !!user?.tasks?.length ? user.tasks : [];
  const notebook = !!module?.name && !!challenger?.name;
  const backgroundImage = !!notebook && !!module?.background_image_url 
    ? module.background_image_url 
    : laboratory?.background_image_url;
  const backgroundVideo = !!notebook && !!module?.background_video_url 
    ? module.background_video_url
    : laboratory?.background_video_url;
  const backgroundVideoAudio = !!notebook && !!module?.background_video_url 
    ? !!module?.background_video_audio
    : laboratory?.background_video_audio;
  const backgroundVideoAutoplay = !!notebook && !!module?.background_video_url 
    ? !!module?.background_video_url 
    : laboratoryBackgroundVideoAutoplay;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (!!isLoaded && !!isEmpty) history.push("/"); }, [isEmpty, isLoaded]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { getLaboratoryCurrent(); }, [user?.nit]);
  
  const getLaboratoryCurrent = async () => {
    const key = match.params.laboratory_key;
    if (!!user?.nit && !!laboratory.name) {
      setLaboratoryBackgroundVideoAutoplay(laboratory?.background_video_autoplay);
    } else if (!!key && !!user?.nit && !laboratory.name) {
      dispatch(actions.getLaboratoryPrivate(key));
    }
  };

  const onCloseZoom = () => dispatch(actions.closePageZoom());
  const changePageZoom = () => dispatch(actions.changePageZoom());
  const openDialogSuccess = () => dispatch(actions.openModalSuccess());

  const selectModule = async (value: IModule) => {
    if (value?.challenges?.length) {
      dispatch(actions.selectModule(value));
    } else messageError(t("El módulo no tiene unidades"));
  }
  const deselectModule = () => dispatch(actions.deselectModule());
  const selectChallenger = (value: IChallenger) => {
    const aux = utils.getChallengerWithTasks(laboratory.name, module, tasks);
    if (value?.name === aux?.name && value?.questions?.length === aux?.questions?.length) {
      dispatch(actions.selectChallenger(aux));
    } else dispatch(actions.selectChallenger({ ...value, selected: true })); 
  }
  const deselectChallenger = () => dispatch(actions.deselectChallenger());

  const saveUserTask = (data: ITask) => {
    const task: ITask = {
      ...data,
      date: new Date().getTime().toString(),
      module: module?.name, 
      laboratory: laboratory?.name, 
      challenger: challenger?.name
    }
    
    if (!!challenger.selected) {
      dispatch(actions.updateUserTask(task, tasks, deselectChallenger));
    } else {
      dispatch(actions.createUserTask({
        task, 
        tasks: tasks, 
        module, 
        challenger, 
        laboratory: laboratory,
        createTaskCallback,
      }));
    }
  }
  const createTaskCallback = (next?: IChallenger, type?: string, task?: ITask) => {
    if (!!next) {
      dispatch(actions.selectChallenger(next));
    } else if (type === "finished") {
      deselectModule();
      dispatch(actions.analytics("perform_laboratory_module"));
    } else deselectChallenger();

    if (!!task) getActionLaboratoryCompleted(task);
  }
  const getActionLaboratoryCompleted = (task: ITask) => {
    const completed = utils.getLaboratoryCompletedUsingProgress(
      laboratory, 
      module,
      challenger,
      [...tasks, task],
    );
    const moduleLast = laboratory?.modules[laboratory?.modules?.length - 1];
    const challengerLast = moduleLast?.challenges[moduleLast?.challenges?.length - 1];
    const condition = 
      completed &&
      moduleLast?.name === module?.name && 
      challengerLast?.name === challenger?.name;
    
    if (condition && !!user?.nit && !!laboratory?.sequential_mode) {
      history.push("/home");
    }
  }

  const onClickBack = () => {
    if (!!module.name) {
      deselectModule();
      !!zoom && onCloseZoom();
    } else history.push("/home"); 
  }

  const generateLaboratoryCertificate = (laboratory: ILaboratory) => {
    dispatch(actions.generateLaboratoryCertificate(laboratory));
  }

  const toogleLaboratoryBackgroundVideoAutoplay = () => {
    setLaboratoryBackgroundVideoAutoplay((v) => !v);
  }

  const handleAnalytics = (name: string, params?: { [key: string]: string }) => {
    dispatch(actions.analytics(name, params));
  }

  return (
    <Grid className={classes.root} container>
      {!!laboratory?.name ? (
        <>
          <AppBarLayout zoom={zoom} hideButtonLanguage={!!user?.nit} />
      
          <Grid className={clsx(classes.page, zoom && "zoom")} container>
            <Background 
              backgroundGif
              backgroundImage={backgroundImage} 
              backgroundVideo={backgroundVideo}
              backgroundVideoAudio={backgroundVideoAudio}
              backgroundVideoAutoplay={backgroundVideoAutoplay}
            />

            <Grid className={classes.content} container>
              {!!notebook ? (
                <Challenger 
                  zoom={zoom} 
                  tasks={tasks}
                  module={module} 
                  challenger={challenger}
                  laboratory={laboratory}
                  taskLoading={taskLoading}
                  dialogSuccess={dialogSuccess}
                  languageAudio={laboratory?.language ?? ""}
                  toogleZoom={changePageZoom}
                  saveUserTask={saveUserTask}
                  handleAnalytics={handleAnalytics}
                  openDialogSuccess={openDialogSuccess}
                  deselectChallenger={deselectChallenger}
                />
              ) : (
                <ModulesList 
                  tasks={user?.tasks ?? []}
                  laboratory={laboratory} 
                  userCurrent={user}
                  moduleCurrent={module}
                  onClickBack={onClickBack}
                  selectModule={selectModule}
                  deselectModule={deselectModule}
                  selectChallenger={selectChallenger}
                  generateLaboratoryCertificate={generateLaboratoryCertificate}
                  toogleBackgroundVideoAutoplay={toogleLaboratoryBackgroundVideoAutoplay}
                />
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}

      <Loading loading={laboratoryLoading} />
    </Grid>
  );
}

export default LaboratoryPage;
