import React, { FC, createRef, useEffect, useState } from "react";
import { IModule } from "dto/module.dto";
import { IChallenger } from "dto/challenger.dto";
import { ILaboratory } from "dto/laboratory.dto";
import { ITask, ITaskRequest } from "dto/user.dto";
import { taskInit } from "initials/task.init";
import NotebookStep from "components/Challenger/NotebookStep";
import DialogSuccess from "components/Challenger/DialogSuccess/DialogSuccess";
import NotebookQuestion from "components/Challenger/NotebookQuestion";

interface IProps {
  zoom: boolean;
  tasks: ITask[];
  module: IModule;
  challenger: IChallenger;
  laboratory: ILaboratory;
  taskLoading: boolean;
  dialogSuccess: boolean;
  languageAudio: string;
  toogleZoom: () => void;
  saveUserTask: (value: ITask) => void;
  handleAnalytics: (name: string, params?: { [key: string]: string; }) => void;
  openDialogSuccess: () => void;
  deselectChallenger: () => void;
  updateQuestionIndex?: (index: number) => void;
}

const Challenger: FC<IProps> = (props) => {
  const [view, setView] = useState(0);
  const [task, setTask] = useState(taskInit);
  const [audioSrc, setAudioSrc] = useState("");
  const [stepLoading, setStepLoading] = useState(false);
  const [buttonSoundImage, setImageButtonAudio] = useState("/img/sound.png");
  const path = `${props.laboratory?.name}/${props.module?.name}/challenges/steps`;
  const audioRef = createRef<HTMLAudioElement>();
  const buttonColor = !!props.challenger?.button_color 
    ? props.challenger.button_color 
    : "#ff6315";
  const primaryColor = !!props.challenger?.primary_color 
    ? props.challenger.primary_color 
    : "#ff6315";
  const secondaryColor = !!props.challenger?.secondary_color 
    ? props.challenger.secondary_color
    : "#e15915";
  const progressBarColor = !!props.challenger?.progress_bar_color 
    ? props.challenger.progress_bar_color 
    : "#ff6315";
    
  document.querySelector("#challenger_question_audio")?.addEventListener(
    "ended",
    () => setImageButtonAudio("/img/sound.png"), 
    false,
  );

  useEffect(() => {
    if (!!props.challenger?.steps?.length) openSteps();
    else if (!!props.challenger?.questions?.length) openQuestions();
    else props.deselectChallenger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.challenger]);

  const openSteps = () => {
    if (!!props.challenger?.steps?.length) setView(0);
    else props.deselectChallenger();
  }
  const openQuestions = () => {
    if (!!props.challenger?.questions?.length) setView(1);
    else openSteps();
  }

  const pauseAudio = () => {
    setImageButtonAudio("/img/sound.png");
    audioRef?.current?.pause();
  }
  const toggleAudio = () => {
    if (!!audioRef?.current?.paused) {
      setImageButtonAudio("/img/pause.png");
      audioRef?.current?.play();
    } else {
      setImageButtonAudio("/img/play.png");
      audioRef?.current?.pause();
    }
  }

  const updateTask = (value: ITaskRequest) => setTask({ ...task, ...value });

  const saveUserTask = () => props.saveUserTask(task);

  return (
    <>
      {view === 0 ? (
        <NotebookStep
          zoom={props.zoom}
          module={props.module}
          loading={stepLoading}
          challenger={props.challenger}
          buttonColor={buttonColor}
          headerColor={secondaryColor}
          languageAudio={props.languageAudio}
          laboratoryName={props.laboratory?.name}
          backgroundColor={primaryColor}
          buttonSoundImage={buttonSoundImage}
          progressBarColor={progressBarColor}
          buttonNextColorText={primaryColor}
          pauseAudio={pauseAudio}
          toggleAudio={toggleAudio}
          openQuestions={openQuestions}
          updateLoading={setStepLoading}
          updateAudiosrc={setAudioSrc}
          toogleZoom={props.toogleZoom}
          openDialogSuccess={props.openDialogSuccess}
          deselectChallenger={props.deselectChallenger}
        />
      ) : view === 1 && !!props.challenger?.questions?.length ? (
        <NotebookQuestion 
          path={path} 
          zoom={props.zoom} 
          questions={props.challenger?.questions}
          languageAudio={props.languageAudio}
          
          buttonColor={buttonColor}
          headerColor={secondaryColor}
          backgroundColor={primaryColor}
          progressBarColor={progressBarColor}
          buttonNextColorText={primaryColor}

          updateTask={updateTask}
          zoomOnClick={props.toogleZoom}
          openUnitSteps={openSteps}
          handleAnalytics={props.handleAnalytics}
          openDialogSuccess={props.openDialogSuccess}
          updateQuestionIndex={props.updateQuestionIndex}
        />
      ) : (
        <></>
      )}

      <DialogSuccess 
        open={props.dialogSuccess}
        task={task}
        tasks={props.tasks}
        module={props.module}
        challenger={props.challenger}
        laboratory={props.laboratory}
        taskLoading={props.taskLoading}
        languageAudio={props.languageAudio}
        saveUserTask={saveUserTask}
        handleAnalytics={props.handleAnalytics}
      />

      <audio id="challenger_question_audio" ref={audioRef} src={audioSrc}>
        Your browser does not support the <code>audio</code> element.
      </audio>
    </>
  )
}

export default Challenger;
