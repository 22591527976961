import React, { FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Document, Page, pdfjs } from "react-pdf";
import { CircularProgress, Grid } from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  type21: { 
    height: "100%", 
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    "& .react-pdf__Document": { 
      width: "100%", 
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .react-pdf__Page": { 
      // width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white", 
      "& .react-pdf__Page__annotations": { display: "none" },
    },
    "& .react-pdf__Page__canvas": {
      // width: "auto !important",
      width: "100% !important",
      height: "auto !important",
      display: "flex !important",
      maxWidth: "100%",
      maxHeight: "100%",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: { minHeight: "auto" },
    },
    "& .react-pdf__Page__textContent": { display: "none" },
  },
  circular: { color: "#ff6300" }
}));

interface IProps {
  pdf?: string;
  pdfWeb?: string;
  pageNumber: number;
  setNumberPages: (page: number) => void;
}

const Type21: FC<IProps> = (props) => {
  const classes = useStyles();
  const file = !!props.pdfWeb ? props.pdfWeb : !!props.pdf ? props.pdf : "";

  const onLoadSuccess = ({ numPages } : { numPages: number }) => {
    props.setNumberPages(numPages);
  }

  return (
    <Grid className={classes.type21} container>
      <Document 
        file={file}
        loading={<CircularProgress className={classes.circular} />}
        onLoadSuccess={onLoadSuccess}
      >
        <Page 
          loading={<CircularProgress className={classes.circular} />}
          pageNumber={props.pageNumber}
        />
      </Document>
    </Grid>
  )
}

export default Type21;
