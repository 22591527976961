import React, { FC, useState } from "react";
import KonvaGroup from "components/Question/QuestionTemplate/KonvaGroups/KonvaGroup";
import GroupButtonRadio from "components/Question/QuestionTemplate/KonvaGroups/GroupButtonRadio";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
  answer_correct: string;
}

const GroupType13: FC<IProps> = (props) => {
  const [answer, setAnswer] = useState("");

  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
    >
      <KonvaGroup
        x={0}
        y={props.height / 2 - 112 / 2}
        width={props.width}
        height={112}
      >
        <GroupButtonRadio
          x={0}
          y={0}
          text="Verdadero"
          width={props.width}
          height={48}
          isSelected={answer === "true"}
          onClick={() => setAnswer("true")}
        />
        <GroupButtonRadio
          x={0}
          y={64}
          text="Falso"
          width={props.width}
          height={48}
          isSelected={answer === "false"}
          onClick={() => setAnswer("false")}
        />
      </KonvaGroup>
    </KonvaGroup>
  );
};

export default GroupType13;
