import i18n from "i18n/i18n";
import { ITemplate, ITemplateItem } from "dto/template.dto";

const stageWidth = 755;
const stageHeight = 535;

export const templateInit: ITemplate = {
  id: "",
  fill: "#ffffff",
  width: 0,
  height: 0,
  template_items: [],
};

export const templateItemInit: ITemplateItem = {
  x: 0,
  y: 0,
  id: "",
  type: "text/text",
  width: 0,
  height: 0,
  scaleX: 1,
  scaleY: 1,
};

export const templateDefault: ITemplate = {
  id: "",
  fill: "#fafafa",
  width: stageWidth,
  height: stageHeight,
  template_items: [
    {
      ...templateItemInit,
      x: 0,
      y: 0,
      id: "",
      fill: "#fc883d",
      type: "shape-single-color",
      width: 755,
      height: 100,
      type_shape: "shape_07",
    },
    {
      ...templateItemInit,
      x: 0,
      y: stageHeight - 70,
      id: "",
      fill: "#ff6300",
      type: "shape-single-color",
      width: 755,
      height: 70,
      type_shape: "shape_08",
    },
    {
      ...templateItemInit,
      x: 0,
      y: 140,
      id: "",
      fill: "#4f4f4f",
      type: "text/text",
      text: i18n.t("Certificado"),
      align: "center",
      width: stageWidth,
      font_size: 36,
      font_style: "bold",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: 0,
      y: 200,
      id: "",
      fill: "#7c7c7c",
      type: "text/text",
      text: i18n.t("Otorgado a"),
      align: "center",
      width: stageWidth,
      font_size: 16,
      font_style: "normal",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: 0,
      y: 220,
      id: "",
      fill: "#484848",
      type: "text/read-only",
      text: i18n.t("nombre_usuario"),
      align: "center",
      width: stageWidth,
      font_size: 40,
      font_style: "bold",
      type_shape: "user",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: 0,
      y: 290,
      id: "",
      fill: "#7c7c7c",
      type: "text/text",
      text: i18n.t("Por completar satisfactoriamente el curso"),
      align: "center",
      width: stageWidth,
      font_size: 16,
      font_style: "normal",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: 0,
      y: 310,
      id: "",
      fill: "#4f4f4f",
      type: "text/read-only",
      text: i18n.t("nombre_laboratorio"),
      align: "center",
      width: stageWidth,
      font_size: 24,
      font_style: "bold",
      type_shape: "laboratory",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: stageWidth / 2 + stageWidth / 2 / 2 - 107 / 2,
      y: 390,
      id: "",
      fill: "#ff6300",
      type: "shape-single-color",
      width: 107,
      height: 42,
      type_shape: "o_lab_02",
    },
    {
      ...templateItemInit,
      x: stageWidth / 2,
      y: 440,
      id: "",
      fill: "#7c7c7c",
      type: "text/text",
      text: "O-lab.app",
      align: "center",
      width: stageWidth / 2,
      font_size: 16,
      font_style: "normal",
      font_family: "Montserrat",
      text_decoration: "empty string",
    },
    {
      ...templateItemInit,
      x: 610,
      y: 150,
      id: "",
      type: "shape-various-color",
      width: 105,
      height: 130,
      colors: ["#2cc113", "#2cc113", "#cdcdcd", "#ffffff"],
      type_shape: "shape_01",
    },
  ],
};
