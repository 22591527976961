import React, { FC } from "react";
import useImage from "use-image";
import { Text, Image } from "react-konva";
import KonvaGroup from "./KonvaGroup";

interface IProps {
  x: number;
  y: number;
  width: number;
  height: number;
  subtitle?: string;
  description?: string;
  header_color?: string;
  image_header?: string;
}

const GroupBubble: FC<IProps> = (props) => {
  const url = !!props.image_header
    ? props.image_header
    : "/gif/default/question/image_header_default.gif";
  const [image] = useImage(url, "anonymous");
  const fill = !!props.header_color ? props.header_color : "#ffebde";

  return (
    <KonvaGroup
      x={props.x}
      y={props.y}
      fill={fill}
      width={props.width}
      height={props.height}
      cornerRadius={16}
    >
      <Image x={0} y={0} image={image} width={100} height={100} />
      <Text
        x={100 + 16}
        y={0}
        fill="#5f5f5f"
        text={props.subtitle}
        width={props.width - 100 - 16 - 16}
        height={props.height / 2 - 4}
        fontSize={16}
        fontStyle="bold"
        fontFamily="Montserrat"
        verticalAlign="bottom"
      />
      <Text
        x={100 + 16}
        y={props.height / 2 + 4}
        fill="#5f5f5f"
        text={props.description}
        width={props.width - 100 - 16 - 16}
        height={props.height / 2 - 4}
        fontSize={16}
        fontFamily="Montserrat"
      />
    </KonvaGroup>
  );
};

export default GroupBubble;
